import React, { useEffect, useRef, useState } from "react";
import StepperFooter from "../components/SolarExternalPages/StepperFooter";
import StepperHeader from "../components/SolarExternalPages/StepperHeader";
import MapScreen from "../components/SolarExternalPages/MapScreen";
import {
  _l,
  addExpandedClasstoIntroOfficeList,
  getDatePickerLocale,
  getDatePickerTimeCaption,
  getPreviewFromURL,
  hideLeftPanelNew,
  hideSolairHubleftPanel,
  isLocalEnvironment,
  showError,
  showSolairHubleftPanel,
  validateEmail,
} from "../hooks/utilities";
import AuthServices from "../services/auth-services";
import CustomerServices from "../services/customer-services";
import SuperficyInput from "../components/SolarExternalPages/SuperficyInput";
import PeopleSelection from "../components/SolarExternalPages/PeopleSelection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Budget from "../components/SolarExternalPages/Budget";
import NameInput from "../components/SolarExternalPages/NameInput";
import CustomPhoneInput from "../components/SolarExternalPages/PhoneInput";
import EmailInput from "../components/SolarExternalPages/EmailInput";
import BenchmarkServices from "../services/benchmark-services";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../actions/messages";
import { ACKNOWLEDGEMENT_STEPS } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import {
  commanoffcanvasisOpen,
  getBenchmarkDetail,
  getCustomerSpaces,
  leftPanelFilters,
  setProject,
  setSelectedTabLeftPanel,
} from "../actions/customer";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProposalListForCustomer from "../components/SolarExternalPages/ProposalListForCustomer";
import CustomerProposalDetail from "../components/SolarExternalPages/CustomerProposalDetail";
import PasswordInput from "../components/SolarExternalPages/PasswordInput";
import footer_certificate from "../assets/images/certificate.png";
import footer_img from "../assets/images/footerimg.png";
import SolarAcknowledgeScreen from "./SolarAcknowledgeScreen";
import SolarFullCalendar from "../components/SolarExternalPages/SolarFullCalendar";
import html2canvas from "html2canvas";
import Loader from "../components/SolarExternalPages/Loader";
import { CaretLeft, CaretRight, CloudArrowUp, Lightning, X } from "phosphor-react";
import AfterPaymentSignature from "../components/SolarExternalPages/AfterPaymentSignature";
import GenericInput from "../components/SolarExternalPages/GenericInput";
import { setShowAccordionVisibility, setShowIsChatScreenDisplay } from "../actions/chat";
import { GoogleMap, useJsApiLoader, Marker,StreetViewPanorama } from "@react-google-maps/api";
import Header from "../components/Header";


const STEPS = {
  BACK: -1,
  NEXT: 1,
  SKIP: 0,
};

const STEPS_LIST = [
  "address",
  "superficy",
  "people-count",
  "date-selection",
  "budget",
  "full-name",
  "phonenumber",
  "email",
  "password",
  "stepper-loader",
  "customer-proposal-list",
  "proposal-view",
  "congratulations",
  "tasks",
];

const HIDE_FOOTER = [
  "address",
  "customer-proposal-list",
  "view-customer-proposal-detail",
  "tasks",
];

let solarInfo = {};

const TOTAL_STEPS_COUNT = 16;

const calculatePercentage = (stepIndex) => {
  const percentage = (stepIndex / TOTAL_STEPS_COUNT) * 100;
  return {
    stepCount: stepIndex + 1,
    percentage: percentage
  };
};

const containerStyle = {
  width: "100%",
  height: "100%",
  position: "static",
};

const DEFAULT_VIEWPORT_ZOOM = 18;
const MAX_VIEWPORT_ZOOM = 18.5;
const CUSTOM_SOLAR_PANEL_ZOOM = 19;
const DEFAULT_SOLAR_PANEL_CAPACITY = 375;


const SolarExternalBenchmark = () => {
  const userType = localStorage.getItem("user_type");

  const [currentStep, setCurrentStep] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [userInfo, setUserInfo] = useState({
    address: "Avenue De France, Paris, France",
    superficy: "",
    startDate: "",
    budget: "",
    number_of_people: "",
    fullName: "",
    phonenumber: "",
    email: "",
    password: 12345,
    benchmarkDetails: {},
    region_name: "Île-de-France",
    region_id: 152,
    tasks: [],
    monthly_bill: 0,
    number_of_batteries: "",
    is_installation_urgent: "",
    air_conditioner : 0,
    garage : 0,
    back_yard : 0,
    electronic_gadgets : 0,
  });

  const [textContent, setTextContent] = useState({
    backButtonText: "",
    headerTitle: "l_solar_address_step_header",
    nextButtonText: "l_next",
    stepDescription: "",
    stepperProgress: 0,
    stepTitle: "",
  });
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showStepperLoader, setShowStepperLoader] = useState(false);
  const [currentProposal, setCurrentProposal] = useState({});
  const [houseImages, setHouseImages] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { benchmarkDetail, isLoadingBenchmarkInfo, leftPanelTab } = useSelector(
    (state) => state.customer
  );
  const [addressImageURL, setAddressImageURL] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [showStreetView, setShowStreetView] = useState(false);
  const [isSolarDataFetched, setIsSolarDataFetched] = useState(false);
  const [showInput, setShowInput] = useState(false);


  const mapScreenRef = useRef(null);
  const mapScreenMethodsRef = useRef(null);

  const projectId = searchParams.get("space");
  const benchmarkId = searchParams.get("id");
  const benchmarkType = searchParams.get("type");
  const type = searchParams.get("type");

  const PictureUploadArea = () => {
    const handleFileInput = (e) => {
      setHouseImages(
        Array.from(e.target.files).map((attachment, i) => {
          let url = getPreviewFromURL(attachment.name);

          //Create image blob
          if (typeof url === "string") {
            url = URL.createObjectURL(e.target.files[i]);
          }
          attachment.url = url;
          return attachment;
        })
      );
    };

    useEffect(() => {
      dispatch(setSelectedTabLeftPanel("proposal-list"));
    }, []);

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const page = params.get('page');
      const type = params.get('type');

      if (leftPanelTab == "proposal-list" && page !== "invite-customer-for-benchmark" && type !== "invite-customer") {
        setCurrentStep("customer-proposal-list")
      } else {
          setCurrentStep("address");
          hideLeftPanelNew();
      }
    
    }, [leftPanelTab])
 
    return (
      <React.Fragment>
        <div className="h-100">
          
          {/* Right footer fix start */}
          <div className="border shadow-none text-center position-relative h-100 radius10">
          {houseImages.length ? (
            <div className="d-flex p-2 radius10px shadow-none">
              <div className="upload-image-preview  d-flex flex-wrap justify-content-center">
                {houseImages.map((attachment, index) => {
                  return (
                    <div
                      key={index}
                      className="comman-image-box h50w50 radius_3 upload-image with-margin mb-10 me3rd"
                    >
                      {typeof attachment.url === "string" ? (
                        <>
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                            style={{
                              backgroundImage: `url('${attachment.url}')`,
                            }}
                          ></div>

                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => removeAttachment(attachment)}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </>
                      ) : (
                        <div
                          className="h-100 w-100 d-flex cursor-pointer"
                          style={{
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <attachment.url
                            size={72}
                            weight="light"
                            className="c-icons"
                          />
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => removeAttachment(attachment)}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
            <div className="  p-15 h-100 d-flex align-items-center flex-column">
              <div className=" d-flex align-items-center gap10px justify-content-center w-100 pb-10px">
              <div className="w130pxh100px c-input-box position-relative">
                <div className="form-control file-upload bgblackop002 shadow-none position-relative p-0 flex-grow-1 h-100">
                  <div className="d-flex align-items-center justify-content-between p-15 h-100">
                    <input 
                      className="z-index-3 hidden-input" 
                      type="file"
                    />
                    <div className="m-auto d-flex flex-column">
                      <CloudArrowUp size={30} className="c-icons"/>
                      <span className="c-font color-white-60 f-12 fw-medium pt-1"> {_l("l_file_upload")}</span>
                    </div>
                  </div>
                </div>
            </div>
              <a href="#/" className="object-fit-cover radius_5 w-150px position-relative"
            onClick={()=>{
              const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates.lat},${coordinates.lng}`;

              // Open the URL in a new tab
              window.open(streetViewUrl, '_blank');
              // setShowStreetView(!showStreetView)
            }}>
                <img alt="logo" src={addressImageURL} className="h100px img_height object-fit-cover w-100" />
            </a>
            {/* <a href="#/" className="object-fit-cover radius_5 with-overlay-img w-150px position-relative">
                <img alt="logo" src='https://images.unsplash.com/photo-1610968638007-354cabd54889?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YmlsZGluZ3N8ZW58MHx8MHx8fDA%3D' className="h100px img_height object-fit-cover w-100" />
                <span className="m-auto position-relaive z-index-2 c-font fw-semibold title-fonts f-18 span-color position-absolute top-0 end-0 w-100 h-100 d-flex justify-content-center align-items-center color-yellowtext">4+</span>
            </a> */}
          </div>
              {/* <input
                type="file"
                className="absolute-input"
                multiple
                accept="image/*,video/*"
                onChange={handleFileInput}
              /> */}
              <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_import_video")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_import_video_subtitle")}{" "}
              </div>
              </div>
            </div>
          </div>
          {/* Right footer fix END */}
        </div>
      </React.Fragment>
    );
  };

  const FOOTER_CONTENT = {
    address: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="row border g-0 p-20px radius10 h-100 d-none">
            <div className="col-5 pe-20pximp">
              <div className="h-100 bg-black ratio ratio-21x9 radius_5">
                <img src={footer_certificate} />
              </div>
            </div>
            <div className="col-7 flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                Savez vous que l’electricité à augmenter de 10% en 2024 ?
                Cliquez ici pour voir l’article{" "}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                Bruno Le Maire le ministre des finances, annonce une hausse des
                tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
              </div>
            </div>
          </div>
          {/* end */}
          {/* start */}
          <div className="row border g-0 p-20px radius10 h-100">
            <div className="col-5 pe-20pximp">
              <div className="h-100 ratio ratio-21x9 radius_5">
                <img alt="logo" src={""} className="" />
              </div>
            </div>
            <div className="col-7 flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                Savez vous que l’electricité à augmenter de 10% en 2024 ?
                Cliquez ici pour voir l’article{" "}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                Bruno Le Maire le ministre des finances, annonce une hausse des
                tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
              </div>
            </div>
          </div>
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    superficy: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="border d-flex flex-column flex-sm-row align-items-center g-0 p-15 radius10 order-2 order-lg-1 mt-15px gap-3">
            <a
              href="https://www.youtube.com/watch?v=KjvSVGNTLL4"
              target="_blank"
              className=" radius_5"
            >
              <img alt="logo" src={footer_img} className="h100px radius_5" />
            </a>
            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_superficy_footer_title")}
              </div>
              <div className="c-font f-12 color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_superficy_footer_subtitle")}
              </div>
            </div>
          </div>
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    batteries: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="border d-flex g-0 p-15 radius10">
            <a
              href="https://www.youtube.com/watch?v=KjvSVGNTLL4"
              target="_blank"
              className=" radius_5"
            >
              <img alt="logo" src={footer_img} className="h100px radius_5" />
            </a>
            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_superficy_footer_title")}
              </div>
              <div className="c-font f-12 color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_superficy_footer_subtitle")}
              </div>
            </div>
          </div>
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "people-count": (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="border d-flex flex-column flex-sm-row align-items-center g-0 p-15 radius10 order-2 order-lg-1 mt-15px gap-3">
            <a
              href="https://www.youtube.com/watch?v=KjvSVGNTLL4"
              target="_blank"
              className=" radius_5"
            >
              <img alt="logo" src={footer_img} className="h100px radius_5" />
            </a>

            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_peoplecount_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_peoplecount_footer_subtitle")}
              </div>
            </div>
          </div>
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "date-selection": (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="border d-flex align-items-center flex-column flex-sm-row g-0 p-15 radius10 order-lg-1 order-2 mt-15px gap-3">
          <a href="https://www.youtube.com/watch?v=KjvSVGNTLL4" target="_blank" class=" radius_5">
            <img src="/static/media/footerimg.ae205cfe.png" class="h100px radius_5" alt="logo" />
          </a>

            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_date_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_date_footer_subtitle")}
              </div>
            </div>
          </div>
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    budget: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="border d-flex g-0 p-15 radius10">
            <a
              href="https://www.youtube.com/watch?v=KjvSVGNTLL4"
              target="_blank"
              className=" radius_5"
            >
              <img alt="logo" src={footer_img} className="h100px radius_5" />
            </a>

            <div className="ps-20pximp flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_budget_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_budget_footer_subtitle")}
              </div>
            </div>
          </div>
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "full-name": (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          {/* <div className="border d-flex g-0 p-2 radius10 flex-column flex-sm-row align-items-center order-lg-1 order-2 mt-15px gap-3 info_card">
            <div className=" radius_5 min-width120">
              <img alt="logo" src={footer_certificate} className="h100px img_height w-100" />
            </div>

            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_fullname_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_fullname_footer_subtitle")}
              </div>
            </div>
          </div> */}
          {/* <a href="#/" className="border mt-15px p-10 position-fixed radius10 start-0 info_card_left_position"
          onClick={()=>{
            const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates.lat},${coordinates.lng}`;

            // Open the URL in a new tab
            window.open(streetViewUrl, '_blank');
            // setShowStreetView(!showStreetView)
          }}>
            <div className="object-fit-cover radius_5">
              <img alt="logo" src={addressImageURL} className="h100px img_height object-fit-cover w-100" />
            </div>
          </a> */}
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    phonenumber: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          {/* <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center order-2 order-lg-1 mt-15px gap-3 info_card">
            <div className=" radius_5 min-width120">
              <img alt="logo" src={footer_certificate} className="h100px img_height w-100" />
            </div>
            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_phonenumber_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_phonenumber_footer_subtitle")}
              </div>
            </div>
          </div> */}
          {/* <a href="#/" className="border mt-15px p-10 position-fixed radius10 start-0 info_card_left_position"
          onClick={()=>{
            const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates.lat},${coordinates.lng}`;

            // Open the URL in a new tab
            window.open(streetViewUrl, '_blank');
            // setShowStreetView(!showStreetView)
          }}>
            <div className="object-fit-cover radius_5">
              <img alt="logo" src={addressImageURL} className="h100px img_height object-fit-cover w-100" />
            </div>
          </a> */}
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    email: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20 mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          {/* <div className="border  d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center order-lg-1 order-2 mt-15px gap-3 info_card">
          <div className=" radius_5 min-width120">
              <img alt="logo" src={footer_certificate} className="h100px img_height w-100" />
            </div>
            <div className="flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_email_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                {_l("l_email_footer_subtitle")}
              </div>
              </div>
          </div> */}
          {/* <a href="#/" className="border mt-15px p-10 position-fixed radius10 start-0 info_card_left_position"
          onClick={()=>{
            const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates.lat},${coordinates.lng}`;

            // Open the URL in a new tab
            window.open(streetViewUrl, '_blank');
            // setShowStreetView(!showStreetView)
          }}>
            <div className="object-fit-cover radius_5">
              <img alt="logo" src={addressImageURL} className="h100px img_height object-fit-cover w-100" />
            </div>
          </a> */}
          {/* Right footer fix start */}
          
            {/* Right footer fix start */}
            {PictureUploadArea()}
            {/* Right footer fix END */}
         
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    password: (
      <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          {/* <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center order-lg-1 order-2  mt-15px gap-3 info_card">
            <div className=" radius_5 min-width120">
              <img alt="logo" src={footer_certificate} className="h100px img_height w-100" />
            </div>
             <div className="flex-grow-1">
            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                {_l("l_password_footer_title")}
              </div>
              <div className="color-white-60 light-theme-color-white-70  title-fonts c-font f-12">
                {_l("l_password_footer_subtitle")}
              </div>
              </div>
          </div> */}
          {/* <a href="#/" className="border mt-15px p-10 position-fixed radius10 start-0 info_card_left_position"
          onClick={()=>{
            const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates.lat},${coordinates.lng}`;

            // Open the URL in a new tab
            window.open(streetViewUrl, '_blank');
            // setShowStreetView(!showStreetView)
          }}>
            <div className="object-fit-cover radius_5">
              <img alt="logo" src={addressImageURL} className="h100px img_height object-fit-cover w-100" />
            </div>
          </a> */}
          {/* Right footer fix start */}
          {PictureUploadArea()}
          {/* Right footer fix END */}
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "customer-proposal-list": (
      <div className="col-7 mt-auto mx-auto row d-none">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="row border g-0 p-20px radius10 h-100">
            <div className="col-5 pe-20pximp">
              <div className="h-100 ratio ratio-21x9 radius_5">
                <img alt="logo" src={""} className="" />
              </div>
            </div>
            <div className="col-7 flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                Savez vous que l’electricité à augmenter de 10% en 2024 ?
                Cliquez ici pour voir l’article{" "}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                Bruno Le Maire le ministre des finances, annonce une hausse des
                tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
              </div>
            </div>
          </div>
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "view-customer-proposal-detail": (
      <div className="col-7 mt-auto mx-auto row d-none">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="row border g-0 p-20px radius10 h-100">
            <div className="col-5 pe-20pximp">
              <div className="h-100 ratio ratio-21x9 radius_5">
                <img alt="logo" src={""} className="" />
              </div>
            </div>
            <div className="col-7 flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                Savez vous que l’electricité à augmenter de 10% en 2024 ?
                Cliquez ici pour voir l’article{" "}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                Bruno Le Maire le ministre des finances, annonce une hausse des
                tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
              </div>
            </div>
          </div>
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
    "proposal-view": (
      <div className="col-7 mt-auto mx-auto row d-none">
        <div className="col-lg-2"></div>
        <div className="col-lg-7 mx-auto d-flex flex-column px-0">
          {/* start */}
          <div className="row border g-0 p-20px radius10 h-100">
            <div className="col-5 pe-20pximp">
              <div className="h-100 ratio ratio-21x9 radius_5">
                <img alt="logo" src={""} className="" />
              </div>
            </div>
            <div className="col-7 flex-grow-1">
              <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                Savez vous que l’electricité à augmenter de 10% en 2024 ?
                Cliquez ici pour voir l’article{" "}
              </div>
              <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                Bruno Le Maire le ministre des finances, annonce une hausse des
                tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
              </div>
            </div>
          </div>
          {/* end */}
        </div>
        <div className="col-lg-2"></div>
      </div>
    ),
  };

  const captureMapScreenShot = async () => {
    // Ensure the ref is current and html2canvas is loaded
    if (mapScreenRef.current) {
      const canvas = await html2canvas(mapScreenRef.current, { useCORS: true });
      const dataUrl = canvas.toDataURL("image/jpeg");
      solarInfo = { ...solarInfo, image: dataUrl };
    }
  };

  const createSolarBenchmark = async () => {
    try {
      setShowStepperLoader(true);
      setCurrentStep("stepper-loader");
      const response = await BenchmarkServices.createSolarBenchmark({
        ...userInfo,
        phonenumber: `+${userInfo.phonenumber}`,
        houseImages,
        // password: userType === "operator" ? "" : generateRandomString(8)
      });
      if (response.status && response.data) {
        showMessage("l_benchmark_created");
        dispatch(login(userInfo.email, userInfo.password));
        const { project_id, schedule_id, client_id, staff_id, contact_id } =
          response.data;
        localStorage.setItem("staff_id", staff_id);
        localStorage.setItem("client_id", client_id);
        localStorage.setItem("contact_id", contact_id);
        dispatch(setProject(project_id));
        dispatch(getCustomerSpaces(client_id, contact_id));
        searchParams.set("space", project_id);
        searchParams.set("type", "benchmark");
        searchParams.set("id", schedule_id);
        setSearchParams(searchParams);
        localStorage.setItem("client_id", client_id);
        localStorage.setItem("staff_id", staff_id);
        localStorage.setItem("contact_id", contact_id);
        dispatch(getBenchmarkDetail(schedule_id, client_id));
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
      setCurrentStep("password");
    } finally {
      setShowStepperLoader(false);
    }
  };

  const setSolarInfo = (updatedSolarInfo) => {
    solarInfo = { ...solarInfo, ...updatedSolarInfo };
  };

  const handleAddressValidation = async (direction) => {
    if (direction === STEPS.NEXT) {
      if (!solarInfo.address || !solarInfo.address.trim().length) {
        showError("l_please_enter_address");
        return true;
      } else if (
        solarInfo.region_id == 0 &&
        !solarInfo.region_name.trim().length
      ) {
        showError("l_please_enter_valid_address");
        return true;
      } else if (solarInfo.no_of_plates <= 0) {
        showError("l_please_select_solar_configurations");
        return true;
      } else {
        if (regionList.length) {
          let region = regionList.find(
            (w) =>
              w.label &&
              solarInfo.region_name &&
              w.label.trim() == solarInfo.region_name.trim()
          );
          if (region) {
            solarInfo = { ...solarInfo, region_id: region.value };
          }
        }
        await captureMapScreenShot();
        setUserInfo({ ...userInfo, ...solarInfo });
        setCurrentStep(solarInfo.is_installation_urgent ? "date-selection" : "full-name");
        return true;
      }
    } else {
      setIsSolarDataFetched(false);
      setShowInput(true)
      mapScreenMethodsRef.current.eraseAllVerticies();
      if (type == "invite-customer") {
        navigate("/?page=proposals");
      }
      // window.location.href = process.env.REACT_APP_SOLAR_WEB_URL;
    }
    return false;
  };

  const handleSuperficyValidation = (direction) => {
    if (direction === STEPS.BACK && !isLocalEnvironment()) {
      setCurrentStep("address");
    } else if (direction !== STEPS.BACK) {
      // if (
      //   !userInfo.superficy ||
      //   isNaN(Number(userInfo.superficy)) ||
      //   !Number(userInfo.superficy)
      // ) {
      //   showError("l_please_enter_superficy");
      //   return false;
      // } else {
      // }
      setCurrentStep("people-count");
    }
  };

  const handlePeopleCountValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("superficy");
    } else {
      // if (
      //   !userInfo.number_of_people ||
      //   isNaN(Number(userInfo.number_of_people)) ||
      //   !Number(userInfo.number_of_people)
      // ) {
      //   showError("l_please_add_number_of_people");
      //   return false;
      // } else {
      // }
      setCurrentStep("date-selection");
    }
  };

  const handleDateValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("address");
    } else {
      /**As the Event is bind with date input selection, validation is no longer needed, uncomment below code if the button brought back for navigating to next step 
      // if (!userInfo.startDate || !moment(userInfo.startDate).isValid()) 
      // {
      //   showError("l_please_select_valid_start_date");
      //   return false;
      // }
      // else 
      */
      {
        setCurrentStep("full-name");
      }
    }
  };

  const handleBudgetValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("date-selection");
    } else {
      if (
        !userInfo.budget ||
        isNaN(Number(userInfo.budget)) ||
        !Number(userInfo.budget)
      ) {
        showError("l_please_add_budget");
        return false;
      } else {
        setCurrentStep("full-name");
      }
    }
  };

  const handleFullNameValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep(userInfo.is_installation_urgent ? "date-selection" : "address");
    } else {
      if (!userInfo.fullName || !userInfo.fullName.trim().length) {
        showError("l_please_enter_name");
        return false;
      } else {
        setCurrentStep("email");
      }
    }
  };

  const handleEmailValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("full-name");
    } else {
      if (!userInfo.email || !validateEmail(userInfo.email)) {
        showError("l_please_enter_valid_email");
        return false;
      } else {
        AuthServices.checkEmailforSetPassword(userInfo.email).then(
          (response) => {
            if (response.status) {
              showError("l_email_already_exist");
            } else {
              setCurrentStep("phonenumber");
            }
          }
        );
      }
    }
  };

  const handleInviteCustomer = async () => {
    try {
      setShowLoading(true);
      const response = await BenchmarkServices.createSolarBenchmark({
        ...userInfo,
        phonenumber: `+${userInfo.phonenumber}`,
        is_invite: 1,
      });
      if (response.status) {
        navigate(
          `/?page=proposals&proposal=${response.data.proposal_id}&id=${response.data.schedule_id}&space=${response.data.project_id}`
        );
        return;
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowLoading(false);
    }
  };

  const handlePhonenumberValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("email");
    } else {
      if (benchmarkType === "invite-customer") {
        handleInviteCustomer();
        return;
      }
      if (!userInfo.phonenumber || !userInfo.phonenumber.trim().length) {
        showError("l_phone_number_cannot_be_empty");
        return false;
      } else {
        //Set default password and not ask user to set
        // setCurrentStep("password");
        createSolarBenchmark();
      }
    }
  };

  const handlePasswordValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("phonenumber");
    } else {
      if (!userInfo.password || !userInfo.password.length) {
        showError("l_please_enter_password");
        return false;
      } else {
        createSolarBenchmark();
      }
    }
  };

  const handleBatteriesValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("address");
    } else {
      setCurrentStep("date-selection");
    }
  };

  const selectSolarCategory = () => {
    //Select Solar category
    dispatch(
      leftPanelFilters({
        main_key: "providers",
        filter_key: "1",
        title: "Blaz",
        image: "",
        main_sub_key: "providers",
        sub_category: [],
      })
    );
    navigate(
      `/?main_key=providers&filter_key=1&main_sub_key=providers&space=${benchmarkDetail.project_id}`
    );
  };

  const changeStep = (direction) => {
    switch (currentStep) {
      case "address":
        handleAddressValidation(direction);
        break;
      case "batteries":
        handleBatteriesValidation(direction);
        break;
      case "superficy":
        handleSuperficyValidation(direction);
        break;
      case "people-count":
        handlePeopleCountValidation(direction);
        break;
      case "date-selection":
        handleDateValidation(direction);
        break;
      case "budget":
        handleBudgetValidation(direction);
        break;
      case "full-name":
        handleFullNameValidation(direction);
        break;
      case "phonenumber":
        handlePhonenumberValidation(direction);
        break;
      case "email":
        handleEmailValidation(direction);
        break;
      case "password":
        handlePasswordValidation(direction);
        break;
      case "customer-proposal-list":
        break;
      case "view-customer-proposal-detail":
        // setCurrentProposal({});
        if(direction === STEPS.BACK)
        {
          setCurrentStep("customer-proposal-list");
        dispatch(setShowIsChatScreenDisplay(true));

        }
        else {
          setCurrentStep("after-payment-signature");
        }
        break;
      case "after-payment-signature":
        fetchTasks();
        setCurrentStep("tasks");
        break;
      case "proposal-view":
        break;
      case "congratulations":
        break;
      case "tasks":
        selectSolarCategory();
        break;
    }
  };

  const viewProposalDetail = (proposalDetail) => {
    hideSolairHubleftPanel();
    setCurrentStep("view-customer-proposal-detail");
    setCurrentProposal(proposalDetail);
  };

  const rejectProposal = () => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_reject_proposal"),
        _l("l_reject"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            setShowLoading(true);
            const response = await BenchmarkServices.rejectSolarProposal(
              benchmarkDetail.project_id,
              benchmarkDetail.id,
              currentProposal.proposal_id
            );
            if (response.status) {
              setCurrentProposal({});
              setCurrentStep("customer-proposal-list");
              dispatch(setShowIsChatScreenDisplay(true));
              dispatch(getBenchmarkDetail(benchmarkDetail.id));
            } else {
              showError(response.message);
            }
          } catch (e) {
            console.error(e);
            showError("l_something_went_wrong");
          } finally {
            setShowLoading(false);
            dispatch(toastCloseAction());
          }
        }
      )
    );
  };

  const fetchTasks = () => {
    dispatch(setProject(projectId));
    dispatch(getBenchmarkDetail(benchmarkDetail.id));
  };

  const removeAttachment = (attachment) => {
    setHouseImages(
      Array.from(houseImages).filter((w) => {
        if (w.name != attachment.name) {
          return w;
        }
        if (typeof getPreviewFromURL(attachment.name) === "string") {
          URL.revokeObjectURL(attachment.url);
        }
      })
    );
  };

  useEffect(() => {
    const fetchedRegions = () => {
      CustomerServices.getRegionList()
        .then((res) => {
          if (res.status == 1 && res.data) {
            setRegionList(
              res.data.map((data) => {
                return {
                  label: data.name,
                  value: data.id,
                };
              })
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsPageLoaded(true);
        });
    };

    fetchedRegions();
    if (projectId > 0 && benchmarkId > 0) {
      dispatch(setProject(projectId));
      dispatch(getBenchmarkDetail(benchmarkId));
    } else {
      setCurrentStep("address");
    }
    document.body.classList.remove("with-left-penel");

    const element = document.getElementById("left-panel-main");
    if (element) {
      element.classList.add("d-none");
    }
    return () => {
      document.body.classList.add("with-left-penel");
      if (element) {
        element.classList.remove("d-none");
      }
    };
  }, [benchmarkId]);

  useEffect(() => {
    if (benchmarkDetail && Object.keys(benchmarkDetail).length) {
      if (currentStep === "stepper-loader") {
        searchParams.set("id", benchmarkDetail.id);
        setSearchParams(searchParams);
        setCurrentStep("customer-proposal-list");
        dispatch(setShowIsChatScreenDisplay(true));

      }
      else if (
        benchmarkDetail.proposal_list &&
        benchmarkDetail.proposal_list.length &&
        benchmarkDetail.proposal_list[0].proposals &&
        benchmarkDetail.proposal_list[0].proposals.find((w) => w.status == 3)
      ) {
        if(benchmarkDetail.is_after_payment_document_pending == 1)
        {
          setCurrentStep("after-payment-signature");
        } 
        else {
          setCurrentStep(currentStep == "view-customer-proposal-detail" ? "view-customer-proposal-detail" :"customer-proposal-list");
        }
      } else if (
        benchmarkDetail.id > 0 &&
        currentStep !== "view-customer-proposal-detail"
      ) {
        setCurrentStep("customer-proposal-list");
        dispatch(setShowIsChatScreenDisplay(true));

      }
      const element = document.getElementById("left-panel-main");
      if (element) {
        element.classList.remove("d-none");
      }
    }
  }, [JSON.stringify(benchmarkDetail)]);

  //To manage the step related data like the header text, progress bar, back & next button, etc
  useEffect(() => {
    let textContentData = {
      backButtonText: "",
      headerTitle: "l_solar_address_step_header",
      nextButtonText: "l_next",
      stepDescription: "",
      stepperProgress: { 
        stepCount: 0,
        percentage: 0
      },
      stepTitle: "",
    };

    document.body.classList.remove("px-0");

    switch (currentStep) {
      case "address":
        document.body.classList.add("px-0");
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_map_title",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(0),
          stepTitle: "",
          stepCount: 1,
        };
        break;
      case "superficy":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_superficy",
          nextButtonText: "",
          stepDescription: "l_solar_superificy_step_description",
          stepperProgress: calculatePercentage(1),
          stepTitle: "l_solar_superificy_step_title",
        };
        break;
      case "batteries":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_batteries_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_batteries_step_description",
          stepperProgress: calculatePercentage(2),
          stepTitle: "l_solar_batteries_step_title",
        };
        break;
      case "people-count":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_people_count",
          nextButtonText: "",
          stepDescription: "l_solar_people_count_step_description",
          stepperProgress: calculatePercentage(3),
          stepTitle: "l_solar_people_count_step_title",
        };
        break;
      case "date-selection":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_date_selection",
          nextButtonText: "",
          stepDescription: "l_solar_date_selection_step_description",
          stepperProgress: calculatePercentage(4),
          stepTitle: "l_solar_date_selection_step_title",
          stepCount: 2,
        };
        break;
      case "budget":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_budget",
          nextButtonText: "",
          stepDescription: "l_solar_budget_step_description",
          stepperProgress: calculatePercentage(5),
          stepTitle: "l_solar_budget_step_title",
        };
        break;
      case "full-name":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "Votre nom et prénom",
          nextButtonText: "",
          stepDescription: "l_solar_user_full_name_step_description",
          stepperProgress: calculatePercentage(6),
          stepTitle: "l_solar_user_full_name_step_title",
          stepCount: 3,
        };
        break;
      case "email":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "Votre adresse email",
          nextButtonText: "",
          stepDescription: "l_solar_user_email_step_description",
          stepperProgress: calculatePercentage(7),
          stepTitle: "l_solar_user_email_step_title",
          stepCount: 4,
        };
        break;
      case "phonenumber":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "Votre numéro de téléphone",
          nextButtonText: "",
          stepDescription: "l_solar_phonenumber_step_description",
          stepperProgress: calculatePercentage(8),
          stepTitle: "l_solar_phonenumber_step_title",
          stepCount: 5,
        };
        break;
      case "password":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_password",
          nextButtonText: "",
          stepDescription: "l_solar_user_password_step_description",
          stepperProgress: calculatePercentage(9),
          stepTitle: "l_solar_user_password_step_title",
        };
        break;
      case "stepper-loader":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_loading",
          nextButtonText: "",
          stepDescription: "l_solar_stepper_loader_step_description",
          stepperProgress: calculatePercentage(10),
          stepTitle: "l_solar_stepper_loader_step_title",
          showCancelButton: true,
          stepCount: 6,
        };
        break;
      case "customer-proposal-list":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_my_proposals",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(11),
          stepTitle: "",
        };
        break;
      case "view-customer-proposal-detail":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_view_proposal_detail",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(12),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 7,
        };
        break;
      case "after-payment-signature":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_payment_signature",
          nextButtonText: "l_next",
          stepDescription: "l_your_benchamark_has_been_sucessfully_created_and_awaits_your_presences",
          stepperProgress: calculatePercentage(13),
          stepTitle: "l_congratulation",
          stepCount: 8,
        };
        break;
      case "congratulations":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_congratulations",
          nextButtonText: "",
          stepDescription: "l_solar_congratulations_step_description",
          stepperProgress: calculatePercentage(14),
          stepTitle: "l_solar_congratulations_step_title",
          showCancelButton: true,
          stepCount: 8,
        };
        break;
      case "tasks":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_tasks",
          nextButtonText: "l_access_my_account",
          stepDescription: "l_solar_tasks_step_description",
          stepperProgress: calculatePercentage(15),
          stepTitle: "l_solar_tasks_step_title",
          showCancelButton: true,
          stepCount: 9,
        };
        break;
    }
    setTextContent(textContentData);

    if (currentStep == "customer-proposal-list") {
      showSolairHubleftPanel();
    }else if(currentStep == "view-customer-proposal-detail"){
      hideSolairHubleftPanel();
    }

    if (currentStep == "customer-proposal-list" || currentStep == "view-customer-proposal-detail") {
      dispatch(setShowIsChatScreenDisplay(true));
    } else {
      dispatch(setShowIsChatScreenDisplay(false)); 
    }
  }, [currentStep]);

  useEffect(() => {
    setShowLoading(isLoadingBenchmarkInfo);
  }, [isLoadingBenchmarkInfo])
  

  return (
    <div className="d-flex flex-column flex-grow-1 RegNewFlow pb-0 overflow-hidden overflow-y-auto position-relative h-100">
      {showStreetView == true ?
      <div className="comman_action_icon ms-10px mt-10px position-absolute start-0 top-0 z-2">
        <a href="#/" className="action_icon d-flex h40w40 light-theme-white-bg shadow-none"
        onClick={()=>{
          setShowStreetView(false)
        }}>
          <CaretLeft  size={18} className="c-icons" weight="light"/>
        </a>
      </div> : <React.Fragment></React.Fragment>}
      <React.Fragment>
        {showLoading || isLoadingBenchmarkInfo ? (
          <Loader />
        ) : showStreetView == true ? (<>
          <div className="commen-height flex-grow-1" ref={mapScreenRef}>
            <GoogleMap 
              onLoad={() => { }}
              mapContainerStyle={containerStyle}
              zoom={DEFAULT_VIEWPORT_ZOOM}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                mapTypeId: "satellite",
                tilt: 9,
                heading: 9,
              }}
            >
              <StreetViewPanorama
                position={coordinates}
                visible={showStreetView}
                options={{
                  pov: { heading: 100, pitch: 0 },
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: true,
                  motionTracking: false,
                  motionTrackingControl: false

                }}
              />
            </GoogleMap>
          </div>
        </>
        ) : (
          <React.Fragment>
            { ["customer-proposal-list"].includes(currentStep) || [].includes(leftPanelTab) ? <>
            <Header
              projectId={0}
              removeStatusFilter={()=>{}}
              setShowMailModal={()=>{}}
              setSynchronizedMail={()=>{}}
              showMailModal={false}
              hideDriveSyncBtn={true}
              pageName={"my-offres"}
            />
            </> 
            :
            <StepperHeader
              headerClass={
                currentStep === "view-customer-proposal-detail" ? "ms75px" : ""
              }
              backButtonHandler={() => {
                dispatch(setShowAccordionVisibility(true));
                changeStep(STEPS.BACK)
                dispatch(commanoffcanvasisOpen(false));
                addExpandedClasstoIntroOfficeList();
              }}
              nextButtonHandler={() => changeStep(STEPS.NEXT)}
              textContent={textContent}
              hideStepSpan={["customer-proposal-list"].includes(currentStep)}
              stepName={currentStep}
              solarInfo={solarInfo}
            /> }
            {showStepperLoader ||
            (isLoadingBenchmarkInfo && benchmarkId <= 0) ? (
              <div className="solar-acknowledge-screen-main">
                <SolarAcknowledgeScreen
                  afterDismissAction={() => {}}
                  delay={7000}
                  steps={ACKNOWLEDGEMENT_STEPS["solar_plan_1"]}
                  description={
                    "l_feature_allows_experience_entirely_new_way_interacting"
                  }
                  title={"l_onboarding_acknowledgement_header_title"}
                  isSolar={true}
                />
              </div>
            ) : (
              <></>
            )}
            <React.Fragment>
              <div
                className={`${currentStep === "address" ? "h-100" : "d-none"}`}
              >
                {benchmarkId > 0 ||
                (benchmarkDetail && benchmarkDetail.id > 0) ? (
                  // Do not load the iFrame after benchmark creation, as user is no longer able to edit it
                  <React.Fragment></React.Fragment>
                ) : (
                  <MapScreen
                    solarInfo={solarInfo}
                    setSolarInfo={setSolarInfo}
                    regionList={regionList}
                    setRegionList={setRegionList}
                    mapScreenRef={mapScreenRef}
                    captureMapScreenShot={captureMapScreenShot}
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                    setAddressImageURL={setAddressImageURL}
                    setCoordinates={setCoordinates}
                    coordinates={coordinates}
                    setIsSolarDataFetched={setIsSolarDataFetched}
                    isSolarDataFetched={isSolarDataFetched}
                    showInput={showInput}
                    setShowInput={setShowInput}
                    ref={mapScreenMethodsRef}
                  />
                )}
              </div>
              {currentStep === "batteries" ? (
                <GenericInput
                  label={"l_number_of_batteries"}
                  Icon={{ name: Lightning }}
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, number_of_batteries: e.target.value })
                  }
                  placeholder={_l("l_number_of_batteries_placeholders")}
                  type={"number"}
                  value={userInfo.number_of_batteries}
                />
              ) : currentStep === "superficy" ? (
                <SuperficyInput
                  type={"number"}
                  value={userInfo.superficy}
                  placeholder={`Superficie en m²`}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, superficy: e.target.value })
                  }
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "people-count" ? (
                <PeopleSelection
                  name={"people-count"}
                  people={userInfo.number_of_people}
                  setPeople={(people, changeStep) => {
                    setUserInfo({
                      ...userInfo,
                      number_of_people: Number(people),
                    });
                    if (changeStep) {
                      setCurrentStep("date-selection");
                    }
                  }}
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "date-selection" ? (
                <div className="col-12 col-xl-9 col-xxl-8 solarfooter mx-auto row flex-grow-1 mb-20">
                  <div className="col-lg-2"></div>
                  <div className="align-items-center col-lg-7 d-flex mx-auto mb-3 position-relative">
                    {/* Remove display none when the caret needed */}
                    <div class="comman_action_icon absolutetimebtn d-none">
                      <a
                        class="action_icon d-flex h40w40  btn-primary shadow-none "
                        onClick={() => changeStep(STEPS.NEXT)}
                      >
                        <CaretRight
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                    </div>
                    <div className="d-flex justify-content-center RegNewDatePicker">
                      <DatePicker
                        selected={userInfo.startDate}
                        onChange={(date) => {
                          setUserInfo({ ...userInfo, startDate: date });
                          changeStep(STEPS.NEXT);
                        }}
                        onClick={(date) => {
                          setUserInfo({ ...userInfo, startDate: date });
                          changeStep(STEPS.NEXT);
                        }}
                        minDate={new Date()}
                        locale={getDatePickerLocale()}
                        timeCaption={getDatePickerTimeCaption()}
                        inline
                        // showTimeSelect
                      />
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              ) : currentStep === "budget" ? (
                <Budget
                  type={"number"}
                  value={userInfo.budget}
                  placeholder="example:300€"
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, budget: e.target.value })
                  }
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "full-name" ? (
                <NameInput
                  type={"text"}
                  value={userInfo.fullName}
                  placeholder={_l("l_your_first_and_last_name")}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, fullName: e.target.value })
                  }
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "phonenumber" ? (
                <CustomPhoneInput
                  onChange={(phone) =>
                    setUserInfo({ ...userInfo, phonenumber: phone })
                  }
                  value={userInfo.phonenumber}
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "email" ? (
                <EmailInput
                  type={"text"}
                  value={userInfo.email}
                  placeholder="email@domain.com"
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, email: e.target.value })
                  }
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "password" ? (
                <PasswordInput
                  type={"password"}
                  value={userInfo.password}
                  placeholder={_l("l_enter_your_password")}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, password: e.target.value })
                  }
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "customer-proposal-list" ? (
                <ProposalListForCustomer onClickHandler={viewProposalDetail} />
              ) : currentStep === "view-customer-proposal-detail" ? (
                <CustomerProposalDetail
                  proposal={currentProposal}
                  rejectProposal={rejectProposal}
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                />
              ) : currentStep === "after-payment-signature" ? (
                <AfterPaymentSignature
                  handleNextStep={() => changeStep(STEPS.NEXT)}
                  proposal={currentProposal}
                />
              ) : currentStep === "tasks" ? (
                <SolarFullCalendar />
              ) : (
                <></>
              )}
            </React.Fragment>
            <StepperFooter
              FooterJSXElement={
                HIDE_FOOTER.find((w) => w == currentStep) ? (
                  <></>
                ) : (
                  FOOTER_CONTENT[currentStep]
                )
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
};

export default SolarExternalBenchmark;
