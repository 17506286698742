import {
  CaretLeft,
  Clock,
  Copy,
  NotePencil,
  PencilSimpleLine,
  PhoneCall,
  ShareNetwork,
  Trash,
  Note,
  Eye,
  EnvelopeOpen,
  Notebook,
  MapPin  
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { _l, roundOffPowerValue } from "../../hooks/utilities";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import EditClientDataModal from "../Modals/EditClientDataModal";
import ReminderModal from "../Modals/ReminderModal";
import { useDispatch, useSelector } from "react-redux";
import benchmarkServices from "../../services/benchmark-services";
import AddEmailTemplatesModalNew from "../Modals/AddEmailTemplatesModalNew";
import { showConfirmation, showMessage } from "../../actions/messages";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import Spinner from "react-bootstrap/Spinner";


const StepperHeader = ({
  textContent,
  backButtonHandler,
  nextButtonHandler,
  headerClass,
  hideStepSpan,
  stepName,
  isfromTopSetEditProf,
  solarInfo,
  isExternal = false,
  pageName = '',
  setIsUpdate = () => {},
  loadingNextStep = false,
  handler = () => {},
  deleteProposalHandler=()=>{},
  setOpenNoteModal=() =>{},
  setproposalModal = () =>{},
}) => {
  const dispatch = useDispatch();
  const { reminderList, leftPanelTab } = useSelector((state) => state.customer);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  // headerTitle, stepTitle, stepDescription, backButtonText, nextButtonText
  const isLoggedIn = localStorage.getItem("accessToken") ? 1 : 0;
  const [editCustomerInfoModal, setEditCustomerInfoModal] = useState(false);
  const [readedReminder, setReadedReminder] = useState();
  const staff_id = localStorage.getItem("staff_id");
  const { benchmarkDetail } = useSelector((state) => state.customer);

  const timeDifference = (inputTime) => {
    const currentTime = new Date();
    const givenTime = new Date(inputTime);
    let diffInSeconds = (currentTime - givenTime) / 1000;
    let isFuture = false;

    if (diffInSeconds < 0) {
      diffInSeconds = Math.abs(diffInSeconds);
      isFuture = true;
    }

    const minutes = Math.floor(diffInSeconds / 60);
    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ${
        isFuture ? "remain" : "ago"
      }`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ${
        isFuture ? "remain" : "ago"
      }`;
    }

    const days = Math.floor(hours / 24);
    if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ${
        isFuture ? "remain" : "ago"
      }`;
    }

    const weeks = Math.floor(days / 7);
    if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ${
        isFuture ? "remain" : "ago"
      }`;
    }

    const months = Math.floor(days / 30);
    return `${months} month${months !== 1 ? "s" : ""} ${
      isFuture ? "remain" : "ago"
    }`;
  };

  const handleReadReminder = (reminder) => {
    try {
      benchmarkServices
        .readSolarReminders("proposal", reminder.rel_id, reminder.id)
        .then((res) => {
          if (res && res.status) {
            setReadedReminder(reminder.id);
          }
        });
    } catch (error) {}
  };

  const handleUpdateProposalDetails = () => {
    setIsUpdate(true)
  }

  useEffect(() => {}, [textContent]);
  const openPopupAndCheckClosed = () => {
    const popupOptions = "width=1200,height=600,left=200,top=100";
    const popupUrl = `https://pdf-module.allisting.com/?page=mapscreenshorts&address=${encodeURIComponent(
      benchmarkDetail.address
    )}&customer_id=${benchmarkDetail.customer_id}`;
    // const popupUrl = `http://localhost:5173/?page=mapscreenshorts&address=${encodeURIComponent(
    //   customerData.postal_address
    // )}&customer_id=${customerData.id}`;
  
   let popupWindow = window.open(popupUrl, "_blank", popupOptions);
  
    const checkPopupClosed = setInterval(() => {
      if (popupWindow.closed) {
        clearInterval(checkPopupClosed);
        // recallCheckListItems()
      }
    }, 500);
  };

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-between comman_action_icon ${
          isfromTopSetEditProf ? "" : "registration-header"
        }`}
      >
        <div className="z-2">
          {textContent.backButtonText ? (
            <a
              className={` action_icon align-items-center d-flex h40w40 light-theme-white-bg p-10-15 shadow-none w-auto with_bg ${
                isfromTopSetEditProf ? "d-none" : ""
              }  ${headerClass}`}
              onClick={backButtonHandler}
            >
              <CaretLeft size={18} weight="light" className="c-icons" />
              <span className="c-font f-12">RETOUR</span>
            </a>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
        <div className={`center-part`}>
        {isExternal || pageName == "solarProviderRegistration" ? <>
        <div className="align-content-center d-flex flex-column justify-content-center text-center">
          <span className="f-12 fw-bold m-auto">
            {benchmarkDetail &&  benchmarkDetail.title 
              ? benchmarkDetail.title 
              : "Installation of solar panels" + " " +roundOffPowerValue(benchmarkDetail && benchmarkDetail.solar_items && benchmarkDetail.solar_items.electricity_production) + " "+"Kwc"} - {benchmarkDetail && benchmarkDetail.company_name}</span>
          <span>{benchmarkDetail &&  benchmarkDetail.address}</span>
        </div>
        </> :
         <div className="align-items-center d-flex flex-column justify-content-center">
            <div className="c-font f-20 text-center title-fonts fw-semibold">
              {hideStepSpan ? (
                <></>
              ) : (
                <span className="text-capitalize">
                  {`${_l("l_step")} ${
                    textContent.stepCount &&
                    solarInfo &&
                    !solarInfo.is_installation_urgent
                      ? textContent.stepCount - 1 || 1
                      : textContent.stepCount
                  }`}{" "}
                  :{" "}
                </span>
              )}
              <span className="text-capitalize">
                {" "}
                {_l(textContent.headerTitle)}{" "}
              </span>
            </div>
            <div className="d-flex progress-slider pt-2 d-none">
              <div className="progress w_400px">
                <div
                  className="progress-bar"
                  style={{
                    width: `${
                      textContent &&
                      textContent.stepperProgress &&
                      textContent.stepperProgress.percentage
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          </div>}
        </div>

        {stepName == "proposal-list" && !["installation-list","adminstrative-list"].includes(leftPanelTab)? (
          <div className="right-part ms-auto">
            {["bottom"].map((placement) => (
              <OverlayTrigger
                trigger="click"
                key={placement}
                placement={placement}
                rootClose
                overlay={
                  <Popover
                    id={`popover-positioned-${placement}`}
                    className="overflowscroll w400px max-h50vh box-shadowpopover"
                  >
                    {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                    <Popover.Body className="p-0 border">
                      <div className="popover-detail-wrapper  py-0">
                        {reminderList &&
                          reminderList.length > 0 &&
                          reminderList.map((data, key) => {
                            return (
                              <>
                                <a
                                  href="#/"
                                  className={`d-flex align-items-center hr_sepretr p-10 w100 notificationlist cursor-pointer ${
                                    readedReminder == data.id ? "" : "unread"
                                  }`}
                                  key={key}
                                  onClick={() => {
                                    handleReadReminder(data);
                                  }}
                                >
                                  <div className="d-flex w100minus100per pe-15px">
                                    <div className="h32w32 comman-round-box with-bg d-flex align-items-center rounded-circle border">
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url(${data.creator_image_url})`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="ps-10px flex-column d-flex">
                                      <div className="c-font f-12 fw-semibold">
                                        {data.creator_name}
                                      </div>
                                      <div className="c-font f-12 color-white-60 line-clamp-2">
                                        {data.description}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column comman_action_icon pe-15px">
                                    <div className="c-font f-10 color-white-60">
                                      {timeDifference(data.date)}
                                    </div>
                                    {/* <a href="#/" className="action_icon ms-auto">
                                                  <DotsThree size={18} weight="bold" className="c-icons" />
                                                </a> */}
                                  </div>
                                </a>
                              </>
                            );
                          })}
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="comman_action_icon">
                  <a href="#/" className="action_icon with_bg border h40w40">
                    <Clock
                      size={18}
                      weight="light"
                      className="c-icons available-slot m-auto"
                    />
                  </a>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}

          {/* Document dropdown start */}
        {stepName == "calendar-task" ? 
        <div className="right-part ms-auto">
          <UncontrolledDropdown className="w-100  position-relative light-theme-blue-bg radius_5">
            <DropdownToggle
              caret
              data-toggle="dropdown"
              tag="a"
              data-bs-auto-close="outside"
              aria-expanded="false"
              className="btn btn-primary dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown"
            >
              Télécharger
            </DropdownToggle>
            <React.Fragment>
              <DropdownMenu
                container="body"
                direction="down"
                className={`dropdown-menu `}
              >
                {benchmarkDetail && benchmarkDetail.benchmark_signed_docs && benchmarkDetail.benchmark_signed_docs.length > 0 ? 
                  benchmarkDetail.benchmark_signed_docs.map((doc) => (
                    <li className="hr_sepretr l_50px">
                      <DropdownItem className="text-truncate">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle" style={{ backgroundImage: `url(${doc.doc_url})` }}>
                            </div>
                          </div>
                          <div className="ps-2 w100minus20 text_wrap">
                            <div className="c-list-detail text-truncate color-white">
                            {doc.doc_name}
                            </div>
                          </div>
                        </div>
                      </DropdownItem>
                    </li>
                  )) 
                  : 
                  <>
                  <div className="align-item-center d-flex h-100 justify-content-center">
                  <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_documents")} />
                  </div>
                  </>}
               
              </DropdownMenu>
            </React.Fragment>
          </UncontrolledDropdown>
        </div>
        : <React.Fragment></React.Fragment>}
        {/* Document dropdown end */}

        {stepName == "proposal-detail" && !isExternal ? (
          <div className="d-flex ms-auto right-part gap10px z-3">
            <a
              className=" d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3"
              data-tip={`${_l("l_notes_and_reminders")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              onClick={() => {
                setOpenNoteModal({ modal: true, id: benchmarkDetail.proposal_id })
              }}
            >
              <Note size={18} weight="light" className="c-icons" />
            </a>
            <a
              className=" d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3"
              data-tip={`${_l("l_activity_log")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              onClick={() => {
                setproposalModal({ modal: true, proposal: benchmarkDetail })
              }}
            >
              <Notebook size={18} weight="light" className="c-icons" />
            </a>
            <a
              className=" d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3"
              data-tip={`${_l("l_email")+ ' ' + _l("l_sent")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              onClick={() => {
                setproposalModal({ modal: true, email_sent: true, proposal: benchmarkDetail })
              }}
            >
              <EnvelopeOpen size={18} weight="light" className="c-icons" />
            </a>
           
            <a href="#/" className={`btn btnyellow ${loadingNextStep ? "for-disabled" : ""}`}
            onClick={()=>{
              handleUpdateProposalDetails()
            }}>
              
              {loadingNextStep ? _l("l_please_wait") : _l("l_save")}
              {
                loadingNextStep ?
                 <Spinner
                 as="span"
                 animation="border"
                 size="sm"
                 role="status"
                 className={`ms-2 ${loadingNextStep ? "opacity-1" : "opacity-0"
                   }`}
                 aria-hidden="true"
               /> : <></>
              }
            </a>
            <UncontrolledDropdown className=" position-relative light-theme-blue-bg radius_5">
              <DropdownToggle
                caret
                data-toggle="dropdown"
                tag="a"
                data-bs-auto-close="outside"
                aria-expanded="false"
                className="btn btn-primary dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown"
              >
                Actions
              </DropdownToggle>
              <React.Fragment>
                <DropdownMenu
                  container="body"
                  direction="down"
                  className={`dropdown-menu `}
                >
                  <li className="hr_sepretr l_50px">
                    <DropdownItem className="text-truncate"
                    onClick={()=>{
                      setShowEmailModal(true);
                    }}>
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <ShareNetwork
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            Partager par email
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  <li className="hr_sepretr l_50px">
                    <DropdownItem
                      className="text-truncate"
                      onClick={() => {
                        setShowReminderModal(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Clock
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            Ajouter un rappel
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  <li className="hr_sepretr l_50px">
                    <DropdownItem className="text-truncate"
                    onClick={() => {
                      handler(benchmarkDetail.address)
                    }}
                    disabled={staff_id !== benchmarkDetail && (benchmarkDetail && benchmarkDetail.staff_id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <PencilSimpleLine
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            Modifier (Modifier la proposition)
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  <li className="hr_sepretr l_50px">
                    <DropdownItem
                      className="text-truncate"
                      onClick={() => setEditCustomerInfoModal(true)}
                      disabled={staff_id !== (benchmarkDetail && benchmarkDetail.staff_id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <NotePencil
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            Modifier les informations client (modification
                            rapide)
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_MYR_WEB_URL + "/?page=proposal-deatils&hash=" + benchmarkDetail.benchmark_hash +"&proposal_id=" + benchmarkDetail.proposal_id);
                        dispatch(showMessage("sucess",_l("l_success"),_l("l_link_copied")));
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Copy
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            {_l("l_copy_link")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  {/* <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        setOpenNoteModal({modal: true, id : benchmarkDetail.proposal_id})
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Note
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                          Remarques
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li> */}
                  {/* <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        setproposalModal({modal: true, proposal : benchmarkDetail})
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Notebook
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                          {_l("l_activity_log")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li> */}
                  {/* <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        setproposalModal({modal: true, email_tracking : true, proposal : benchmarkDetail})
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Eye
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                          {_l("l_email_tracking")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li> */}
                  {/* <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        setproposalModal({modal: true, email_sent : true, proposal : benchmarkDetail})
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <EnvelopeOpen
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                          {_l("l_email")+ ' ' + _l("l_sent")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li> */}
                  <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={() => {
                        dispatch(
                          showConfirmation(
                            _l(
                              "l_are_you_sure"
                            ),
                            _l(
                              "l_you_want_to_delete_proposal"
                            ),
                            _l("l_yes"),
                            _l("l_no"),
                            undefined,
                            () => {
                              deleteProposalHandler(benchmarkDetail, true);
                            }
                          )
                        );
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <Trash
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            {_l("l_delete")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                  <li className="hr_sepretr l_50px">
                    <DropdownItem
                      tag={`a`}
                      className="text-truncate"
                      onClick={()=>{
                        openPopupAndCheckClosed();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                            <MapPin 
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <div className="ps-2 w100minus20 text_wrap">
                          <div className="c-list-detail text-truncate color-white">
                            {_l("generate")}
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </li>
                </DropdownMenu>
              </React.Fragment>
            </UncontrolledDropdown>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="d-flex align-items-center gap-3 z-2">
          <div className="stand-alone-top p-0">
            <Form.Group
              className={`d-none c-input-box position-relative with_separator_10 before-dnone`}
            >
              <Select
                className="custom-select-menu with-filter-select me-0 border-0 min-w-auto bg-transparent"
                closeMenuOnSelect={true}
                // placeholder={`${_l(language ? language : "l_FR")}`}
                classNamePrefix="react-select"
                // options={languageSelects}
                // value={languageSelects.filter(dd=> dd.value == language)}
                // onChange={(value)=>{setLanguage(value.value)
                // setlanguage(value.value)}}
                // menuIsOpen={true}
              />
            </Form.Group>
          </div>
          {textContent.nextButtonText ? (
            <a
              className={`align-items-center d-flex h45w45 px-4 w-auto btn-sm ${loadingNextStep ? "for-disabled" : ""} ${
                stepName == "task-details" ? 
                "btn btn-success"
                : ["superficy", "people-count"].includes(stepName)
                  ? "btn-white-05"
                  : "btn-primary"
              }`}
              onClick={nextButtonHandler}
            >
              {loadingNextStep ? _l("l_please_wait") :_l(textContent.nextButtonText)}
              {
                loadingNextStep ?
                 <Spinner
                 as="span"
                 animation="border"
                 size="sm"
                 role="status"
                 className={`ms-2 ${loadingNextStep ? "opacity-1" : "opacity-0"
                   }`}
                 aria-hidden="true"
               /> : <></>
              }
            </a>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {/* {!isLoggedIn && !isExternal? (
            <a href="tel:+33189701600" className="d-flex align-items-center">
              <PhoneCall size={18} weight="light" className="c-icons" />
              <div className="before-block ps-0 with_separator_10"></div>
              <span className="c-font f-18 fw-semibold ps-2 title-fonts">
                {" "}
                +33 1 89 70 16 00
              </span>
            </a>
          ) : (
            <React.Fragment></React.Fragment>
          )} */}
        </div>
      </div>
      {textContent.stepTitle ? (
        <div className="col-12 col-xl-9 col-xxl-8 registration-content mx-auto row registration-step-header">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 m-auto">
            <div className="py-30px position-relative text-center">
              <div className="d-flex align-items-center justify-content-center">
                <div class=" px-0 px-md-3">
                  <div class="c-font f-20 fw-semibold title-fonts">
                    {_l(textContent.stepTitle)}
                  </div>
                  <div class="c-font f-16 pt-15px sub-header text-center">
                    <span>{_l(textContent.stepDescription)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      ) : (
        <></>
      )}
      {editCustomerInfoModal ? (
        <EditClientDataModal
          show={editCustomerInfoModal}
          handleClose={() => {
            setEditCustomerInfoModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {showReminderModal ? (
        <ReminderModal
          show={showReminderModal}
          handleClose={() => {
            setShowReminderModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {showEmailModal ? (
        <AddEmailTemplatesModalNew
          show={showEmailModal}
          handleClose={() => {
            setShowEmailModal(false);
          }}
          checkedBulkLeads={[]}
          data={""}
          from={""}
          lead={""}
          type={"proposal-detail"}
          bulkEmail={false}
          hideRows={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StepperHeader;
