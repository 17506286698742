import React, { useEffect, useRef, useState } from "react";
import StepperHeader from "../components/SolarExternalPages/StepperHeader";
import { _l, addExpandedClasstoIntroOfficeList, addModifiedClass, getBenchmarkScheduleList, getPreviewFromURL, hideLeftPanelNew, isLocalEnvironment, removeExpandedClassFromIntroOfficeList, removeModifiedClass, showError, showLeftPanelNew, showSuccess, showSuccessMessage, validateEmail } from "../hooks/utilities";
import AuthServices from "../services/auth-services";
import CustomerServices from "../services/customer-services";
import "react-datepicker/dist/react-datepicker.css";
import NameInput from "../components/SolarExternalPages/NameInput";
import CustomPhoneInput from "../components/SolarExternalPages/PhoneInput";
import EmailInput from "../components/SolarExternalPages/EmailInput";
import { ACKNOWLEDGEMENT_STEPS } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import {
  commanoffcanvasisOpen,
  getBenchmarkDetail,
  getBenchmarkList,
  getCustomerSpaces,
  setAdministrativeList,
  setInstallationList,
  setProject,
  setReminderList,
  setSelectedGloablView,
  setSelectedTabLeftPanel,
  setTaskProposal,
} from "../actions/customer";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordInput from "../components/SolarExternalPages/PasswordInput";
import Address from "../components/SolarExternalPages/Address";
import { Congratulation } from "../components/SolarExternalPages/Congratulation";
import OperatorProposalList from "../components/SolarExternalPages/OperatorProposalList";
import ProviderProposalDetail from "../components/SolarExternalPages/ProviderProposalDetail";
import StepperFooter from "../components/SolarExternalPages/StepperFooter";
import CompanyInput from "../components/SolarExternalPages/CompanyInput";
import footer_certificate from "../assets/images/certificate.png";
import SolarAcknowledgeScreen from "./SolarAcknowledgeScreen";
import Loader from "../components/SolarExternalPages/Loader";
import BenchmarkServices from "../services/benchmark-services";
import ProviderUploadDocument from "../components/SolarExternalPages/ProviderUploadDocument";
import { X } from "phosphor-react";
import { setShowAccordionVisibility, setShowIsChatScreenDisplay } from "../actions/chat";
import benchmarkServices from "../services/benchmark-services";
import InstallationList from "../components/SolarExternalPages/SolarComponents/InstallationList";
import AdminstrativeList from "../components/SolarExternalPages/SolarComponents/AdminstrativeList";
import MapScreen from "../components/SolarExternalPages/MapScreen";
import html2canvas from "html2canvas";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SolaireDashabord from "../components/SolarExternalPages/SolarComponents/SolaireDashabord";
import customerServices from "../services/customer-services";
import { writeToDB } from "../hooks/indexed_db_helpers";
import { showMessage, toastCloseAction } from "../actions/messages";
import { updateExternalBenchmarkDetails } from "../actions/benchmark-actions/benchmark-actions";
import NoteProposalModal from "../components/Modals/NoteProposalModal";
import ActivityOrEmailTrackingModal from "../components/Modals/ActivityOrEmailTrackingModal";
import CommanOffCanvas from "../components/Offcanvas/CommanOffCanvas";


const STEPS = {
  BACK: -1,
  NEXT: 1,
  SKIP: 0,
};

const STEPS_LIST = [
  "address",
  "company_name",
  "people-count",
  "date-selection",
  "budget",
  "full-name",
  "phonenumber",
  "email",
  "password",
  "stepper-loader",
  "customer-proposal-list",
  "proposal-view",
  "congratulations",
  "tasks",
];



const HIDE_FOOTER = ["address", "proposal-list", "proposal-detail"];

const TOTAL_STEPS_COUNT = 12;

const calculatePercentage = (stepIndex) => {
  const percentage = (stepIndex / TOTAL_STEPS_COUNT) * 100;
  return {
    stepCount: stepIndex + 1,
    percentage: percentage
  };
};

const SolarProviderRegistration = () => {

  // const [houseImages, setHouseImages] = useState([]);

  // const removeAttachment = (attachment) => {
  //   setHouseImages(
  //     Array.from(houseImages).filter((w) => {
  //       if (w.name != attachment.name) {
  //         return w;
  //       }
  //       if (typeof getPreviewFromURL(attachment.name) === "string") {
  //         URL.revokeObjectURL(attachment.url);
  //       }
  //     })
  //   );
  // };

  // const PictureUploadArea = () => {
    
  //     const handleFileInput = (e) => {
  //       setHouseImages(
  //         Array.from(e.target.files).map((attachment, i) => {
  //           let url = getPreviewFromURL(attachment.name);
    
  //           //Create image blob
  //           if (typeof url === "string") {
  //             url = URL.createObjectURL(e.target.files[i]);
  //           }
  //           attachment.url = url;
  //           return attachment;
  //         })
  //       );
  //     };
    
  //     return (
  //       <React.Fragment>
  //         <div className=" info_card ">
            
  //           {/* Right footer fix start */}
  //           <div className="border info_intro shadow-none text-center position-relative h-auto radius10">
  //           {houseImages.length ? (
  //             <div className="d-flex info_name p-2 radius10px shadow-none">
  //               <div className="upload-image-preview  d-flex flex-wrap justify-content-center">
  //                 {houseImages.map((attachment, index) => {
  //                   return (
  //                     <div
  //                       key={index}
  //                       className="comman-image-box h50w50 radius_3 upload-image with-margin mb-10 me3rd"
  //                     >
  //                       {typeof attachment.url === "string" ? (
  //                         <>
  //                           <div
  //                             className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
  //                             style={{
  //                               backgroundImage: `url('${attachment.url}')`,
  //                             }}
  //                           ></div>
    
  //                           <a
  //                             href="#/"
  //                             className="upload-close"
  //                             onClick={() => removeAttachment(attachment)}
  //                           >
  //                             <X size={10} weight="light" className="c-icons" />
  //                           </a>
  //                         </>
  //                       ) : (
  //                         <div
  //                           className="h-100 w-100 d-flex cursor-pointer"
  //                           style={{
  //                             alignContent: "center",
  //                             flexWrap: "wrap",
  //                           }}
  //                         >
  //                           <attachment.url
  //                             size={72}
  //                             weight="light"
  //                             className="c-icons"
  //                           />
  //                           <a
  //                             href="#/"
  //                             className="upload-close"
  //                             onClick={() => removeAttachment(attachment)}
  //                           >
  //                             <X size={10} weight="light" className="c-icons" />
  //                           </a>
  //                         </div>
  //                       )}
  //                     </div>
  //                   );
  //                 })}
  //               </div>
  //             </div>
  //           ) : (
  //             <React.Fragment></React.Fragment>
  //           )}
  //             <div className="  p-20px p-2">
  //               <input
  //                 type="file"
  //                 className="absolute-input"
  //                 multiple
  //                 accept="image/*,video/*"
  //                 onChange={handleFileInput}
  //               />
  //               <div className="c-font f-12 fw-semibold title-fonts text-uppercase">
  //                 {_l("l_import_video")}
  //               </div>
  //               <div className="c-font f-10 lh-lg pt-2 pt-sm-4   title-fonts">
  //                 {_l("l_import_video_subtitle")}{" "}
  //               </div>
  //             </div>
  //           </div>
  //           {/* Right footer fix END */}
  //         </div>
  //       </React.Fragment>
  //     );
  //   };
    
    const FOOTER_CONTENT = {
      address: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5">
                  <img src={footer_certificate} />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      company_name: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center">
              <div className=" radius_5">
                <img alt="logo" src={footer_certificate} className="h100px" />
              </div>
    
              <div className="ps-20pximp flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  {_l("l_fullname_footer_title")}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  {_l("l_fullname_footer_subtitle")}
                </div>
              </div>
            </div>
            <div className="border info_card radius_3 d-none">
              <div className="d-flex info_name p-20px radius10px shadow-none">
                <div className=""><div className="card_img rounded-5"></div></div>
                <div className="ps-10px">
                  <div className="c-font f-12 fw-bolder f_light-black title-fonts">{_l("l_Jenny_Wilson")}</div>
                  <div className="c-font f-12 fw-medium f_black70 title-fonts line-clamp-2">{_l("l_commented_info")}</div>
                </div>
              </div>
              {/* Right footer fix start */}
              <div className="border info_intro shadow-none text-center position-relative h220px radius10">
                <div className="d-none  p-20px">
                  <input type="file" className="absolute-input" />
                  <div className="c-font f-12 fw-semibold title-fonts text-uppercase">{_l("l_import_video")}</div>
                  <div className="c-font f-10 lh-lg pt-20px pb-20px title-fonts">{_l("l_import_video_subtitle")} </div>
                </div>
                <div className="video_iframe ratio ratio-4x3">
                  {/* <video src=""></video> */}<iframe width="100%" height="100%" src="https://www.youtube.com/embed/9xwazD5SyVg" title="Dummy Video For YouTube API Test" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              {/* Right footer fix END */}
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "full-name": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center">
              <div className=" radius_5">
                <img alt="logo" src={footer_certificate} className="h100px" />
              </div>
    
              <div className="ps-20pximp flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  {_l("l_fullname_footer_title")}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  {_l("l_fullname_footer_subtitle")}
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      phonenumber: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
          <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center">
              <div className=" radius_5">
                <img alt="logo" src={footer_certificate} className="h100px" />
              </div>
    
              <div className="ps-20pximp flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  {_l("l_fullname_footer_title")}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  {_l("l_fullname_footer_subtitle")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      email: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
          <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center">
              <div className=" radius_5">
                <img alt="logo" src={footer_certificate} className="h100px" />
              </div>
    
              <div className="ps-20pximp flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  {_l("l_fullname_footer_title")}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  {_l("l_fullname_footer_subtitle")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      password: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
          <div className="border d-flex g-0 p-10 radius10 flex-column flex-sm-row align-items-center">
              <div className=" radius_5">
                <img alt="logo" src={footer_certificate} className="h100px" />
              </div>
              <div className="ps-20pximp flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  {_l("l_fullname_footer_title")}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  {_l("l_fullname_footer_subtitle")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "customer-proposal-list": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "view-customer-proposal-detail": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "proposal-view": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "customer-proposal-list": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      tasks: (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
      "proposal-view": (
        <div className="col-12 col-xl-9 col-xxl-8 registration-footer-content solarfooter mt-auto mx-auto row mb-20">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 mx-auto">
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-5 pe-20pximp">
                <div className="h-100 bg-black ratio ratio-21x9 radius_5"></div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10">
              <div className="col-5 pe-20pximp">
                <div className="h-100 ratio ratio-21x9 radius_5">
                  <img alt="logo" src={""} className="" />
                </div>
              </div>
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Savez vous que l’electricité à augmenter de 10% en 2024 ? Cliquez
                  ici pour voir l’article{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Bruno Le Maire le ministre des finances, annonce une hausse des
                  tarifs de l’electricité entre 8,6 et 9,8% au 1er février 2024
                </div>
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div className="row border g-0 p-20px radius10 h-100 d-none">
              <div className="col-7 flex-grow-1">
                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                  Ajouter votre adresse email afin de recevoir un récapitulatif de
                  votre demande{" "}
                </div>
                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                  Votre email sera aussi votre identifiant en cas de connexion à
                  votre compte Blaz.com
                </div>
              </div>
            </div>
            {/* end */}
          </div>
          <div className="col-lg-2"></div>
        </div>
      ),
    };

  const token = localStorage.getItem("accessToken");
  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  const [currentStep, setCurrentStep] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [userInfo, setUserInfo] = useState({
    address: "",
    company_name: "",
    startDate: new Date(),
    budget: "",
    number_of_people: "",
    fullName: "",
    phonenumber: "",
    email: "",
    password: "",
    benchmarkDetails: {},
    region_name: "",
    region_id: 0,
    tasks: [],
    provider_address: [],
    company_documents: {
      companyLogo: "", 
      companyId: "", 
      companyRGE: "", 
      companyQC: "", 
      companyInsurance: "",
      companymandate: "",
      companyPI : ""
    }
  });
  const [textContent, setTextContent] = useState({
    backButtonText: "",
    headerTitle: "l_solar_address_step_header",
    nextButtonText: "",
    stepDescription: "",
    stepperProgress: { 
      stepCount: 0,
      percentage: 0
    },
    stepTitle: "",
  });
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [showStepperLoading, setShowStepperLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showCongratulationLoader, setShowCongratulationLoader] =
    useState(false);
  const [proposalList, setProposalList] = useState([]);
  const [iniProposalList, setIniProposalList] = useState([]);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { benchmarkDetail, isLoadingBenchmarkInfo,leftPanelTab, globalSearchString, operatorProposalList, isTaskProposal } = useSelector(
    (state) => state.customer
  );
  const mapScreenRef = useRef(null);

  const FooterJSX = () => {
    return FOOTER_CONTENT[currentStep];
  };


  const [addressImageURL, setAddressImageURL] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [showStreetView, setShowStreetView] = useState(false);
  const [isSolarDataFetched, setIsSolarDataFetched] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [solarInfo, setSolarInfo] = useState({})
  const projectId = searchParams.get("space");
  const benchmarkId = searchParams.get("id");
  const externalProposalHash = searchParams.get("hash");
  const page = searchParams.get("page");
  const proposalId = searchParams.get("proposal");
  const [isUpdate, setIsUpdate] = useState(false);
  const [showSckeleton, setShowSckeleton] = useState(false);
  const [showLoaderHeader, setShowLoaderHeader] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState({modal: false, id : 0});
  const [proposalModal, setproposalModal] = useState({modal : false});
  const [showTaskCanvas, setShowTaskCanvas] = useState(false);
  const [taskDetail, setTaskDetail] = useState();


  const isExternal = page === "external-proposal" && externalProposalHash.length;

  const registerSolarProvider = async () => {
    setShowStepperLoading(true);
    setCurrentStep("stepper-loader");
    try {
      const response = await AuthServices.externalUserRegistratoin(
        {
          ...userInfo,
          first_name: userInfo.fullName,
          company_address: userInfo.address,
          user_type: 2,
          phonenumber: `+${userInfo.phonenumber}`,
        },
        [],
        [],
        6,
        []
      );
      if (response.status && response.data) {
        setShowCongratulationLoader(true);
        setCurrentStep("congratulations");
        dispatch(login(userInfo.email, userInfo.password));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowStepperLoading(false);
    }
  };

  const handleAddressValidation = (direction) => {
    if (direction === STEPS.NEXT) {
      if (!userInfo.address || !userInfo.address.trim().length) {
        showError("l_please_enter_address");
        return true;
      } else if (
        userInfo.region_id == 0 &&
        !userInfo.region_name.trim().length
      ) {
        showError("l_please_enter_valid_address");
        return true;
      } else {
        setCurrentStep("company_name");
        return true;
      }
    }
    return false;
  };

  const handleCompanyNameValidation = (direction) => {
    if (direction === STEPS.BACK && !isLocalEnvironment()) {
      setCurrentStep("address");
    } else {
      if (!userInfo.company_name || !userInfo.company_name.trim().length) {
        showError("l_please_enter_company_name");
        return false;
      } else {
        setCurrentStep("full-name");
      }
    }
  };

  const handleFullNameValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("company_name");
    } else {
      if (!userInfo.fullName || !userInfo.fullName.trim().length) {
        showError("l_please_enter_name");
        return false;
      } else {
        setCurrentStep("email");
      }
    }
  };

  const handleEmailValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("full-name");
    } else {
      if (!userInfo.email || !validateEmail(userInfo.email)) {
        showError("l_please_enter_valid_email");
        return false;
      } else {
        AuthServices.checkEmailforSetPassword(userInfo.email).then(
          (response) => {
            if (response.status) {
              showError("l_email_already_exist");
            } else {
              setCurrentStep("phonenumber");
            }
          }
        );
      }
    }
  };

  const handlePhonenumberValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("email");
    } else {
      if (!userInfo.phonenumber || !userInfo.phonenumber.trim().length) {
        showError("l_phone_number_cannot_be_empty");
        return false;
      } else {
        setCurrentStep("password");
      }
    }
  };

  const handlePasswordValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("phonenumber");
    } else {
      if (!userInfo.password || !userInfo.password.length) {
        showError("l_please_enter_password");
        return false;
      } else {
        setCurrentStep("documents")
      }
    }
  };

  const handleComapnyDocuments = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("password");
    } else {
      registerSolarProvider();
    }
  };

  const handleEditFullNameValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("edit-address");
    } else {
      if (!userInfo.fullName || !userInfo.fullName.trim().length) {
        showError("l_please_enter_name");
        return false;
      } else {
        setCurrentStep("edit-email");
      }
    }
  };

  const handleEditEmailValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("edit-full-name");
    } else {
      if (!userInfo.email || !validateEmail(userInfo.email)) {
        showError("l_please_enter_valid_email");
        return false;
      } else {
        setCurrentStep("edit-phonenumber");
      }
    }
  };

  const handleEditPhonenumberValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("edit-email");
    } else {
      if (!userInfo.phonenumber || !userInfo.phonenumber.trim().length) {
        showError("l_phone_number_cannot_be_empty");
        return false;
      } else {
          try {
          setShowStepperLoading(true)
          const { fullName, email, phonenumber } = userInfo;
           benchmarkServices.editInvitedCustomerDetail(
            benchmarkDetail.id,
            benchmarkDetail.project_id,
            fullName.split(" ")[0],
            fullName.split(" ").slice(1).join(" "),
            phonenumber,
            email,
          )
           .then((response) => {
             if (response.status) {
              showSuccessMessage("l_customer_details_updated_succsessfully")
              setCurrentStep("proposal-detail");
              setUserInfo({
                address: "",
                company_name: "",
                startDate: new Date(),
                budget: "",
                number_of_people: "",
                fullName: "",
                phonenumber: "",
                email: "",
                password: "",
                benchmarkDetails: {},
                region_name: "",
                region_id: 0,
                tasks: [],
                provider_address: [],
                company_documents: {
                  companyLogo: "", 
                  companyId: "", 
                  companyRGE: "", 
                  companyQC: "", 
                  companyInsurance: "",
                  companymandate: "",
                  companyPI : ""
                },
                is_edit: false
              })
              setSolarInfo({});
            } else {
              showError(_l(response.message));
            }
            setShowStepperLoading(false);
           }
           )
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
      }
    }
  };

  const handleUpdateSolarPnelsandAddress = async(direction) => {
    if (direction == -1) {
      setCurrentStep("proposal-detail");
      return
    }
    try {
      let response = await benchmarkServices.updateSolarBenchmarkDetails(
        benchmarkDetail.id,
        benchmarkDetail.project_id,
        benchmarkDetail.startdate,
        benchmarkDetail.title,
        benchmarkDetail.proposal_id,
        true,
        solarInfo
      )
      if (response && response.status) {
        dispatch(getBenchmarkDetail(benchmarkDetail.id));
        getProposalList(false);
        showSuccess("l_data_updated")

      }else{
        showError(response && response.message ? response.message : "l_something_went_wrong");
      }
    } catch (error) {
      console.log(error)
    }
    setCurrentStep("edit-full-name");
  }

  const changeStep = (direction) => {
    switch (currentStep) {
      case "address":
        handleAddressValidation(direction);
        break;
      case "company_name":
        handleCompanyNameValidation(direction);
        break;
      case "full-name":
        handleFullNameValidation(direction);
        break;
      case "phonenumber":
        handlePhonenumberValidation(direction);
        break;
      case "email":
        handleEmailValidation(direction);
        break;
      case "password":
        handlePasswordValidation(direction);
        break;
      case "documents":
        handleComapnyDocuments(direction);
      break;
      case "proposal-detail":
        getProposalList();
        break;
      case "congratulations":
        break;
      case "tasks":
        navigate("/");
        break;
      case "edit-address":
          handleUpdateSolarPnelsandAddress(direction)
        break;
      case  "edit-full-name" :
        handleEditFullNameValidation(direction);
        break;
      case  "edit-phonenumber" :
        handleEditPhonenumberValidation(direction);
        break;
      case  "edit-email" :
        handleEditEmailValidation(direction);
        break;
    }
  };

  const viewProposalDetail = (proposalDetail) => {
    dispatch(setShowAccordionVisibility(false));
    dispatch(commanoffcanvasisOpen(true));
    removeExpandedClassFromIntroOfficeList();
    hideLeftPanelNew();

    if(!isExternal)
    {
      dispatch(setProject(proposalDetail.project_id));
      dispatch(getBenchmarkDetail(proposalDetail.id));
      setCurrentStep("proposal-detail");
    }
    else {
      setCurrentStep("address");
      navigate("/?page=register-provider");
    }
  };

  const getProposalList = async (isDefaute = true) => {
    try {
      const response = await getBenchmarkScheduleList("proposals");
      if (response.status) {
        if(page !== "proposals")
        {
          navigate(`/?page=proposals`);
        }
        setProposalList(response.data);
        setIniProposalList(response.data);
        if (isDefaute) {
          setCurrentStep("proposal-list");
        }
        dispatch(setShowIsChatScreenDisplay(true));

      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowCongratulationLoader(false);
      setShowStepperLoading(false);
      setShowLoader(false);
    }
  };

  const getExternalProposals = async () => {
    try {
      const response = await BenchmarkServices.externalProposalList(
        externalProposalHash
      );
      if (response.data) {
        setProposalList(response.data);
        response.data(response.data);
        setCurrentStep("proposal-list");
        dispatch(setShowIsChatScreenDisplay(true));

      }
    } catch (e) {
      console.error(e);
    }
  };

  const captureMapScreenShot = async () => {
    // Ensure the ref is current and html2canvas is loaded
    if (mapScreenRef.current) {
      const canvas = await html2canvas(mapScreenRef.current, { useCORS: true });
      const dataUrl = canvas.toDataURL("image/jpeg");
      setSolarInfo({ ...solarInfo, image: dataUrl });
    }
  };

  const changeTaskStatus = async(status, proposal, isFromDetails = false, amount = 0, setStatusChangeLoader = () => {}) => {
    try {
      setStatusChangeLoader(true);
      let response 
      
      if (status == 2) {
        response = await customerServices.setProposalReject(proposal.project_id, proposal.id, proposal.proposal_id);
      }else if (status == 3) {
        response = await customerServices.setProposalAccetp(proposal.project_id, proposal.id, proposal.proposal_id);
      }else if (status == 4) {
        response = await customerServices.setProposalPending(proposal.project_id, proposal.id, proposal.proposal_id);
      }
      if (response.status) {
        const updateProposals = proposalList.map((pro) => {
          if (proposal.id == pro.id) {
            pro.proposal_status = status
            pro.amount = amount ? amount : pro.amount
          }
          return pro
        })
        setProposalList(updateProposals);
        writeToDB("proposals", `all_proposals`, {filter_key: `all_proposals`, data: updateProposals}, "filter_key");
        if (isFromDetails) {
          const update_proposalList = benchmarkDetail.proposal_list[0].proposals.map((proDe) => {
           if(proDe.proposal_id == proposal.proposal_id){
             proDe.status = status
             proDe.amount = amount ? amount : proDe.amount;
           }
           return proDe;
          })
          benchmarkDetail.proposal_list[0].proposals = update_proposalList
          dispatch(updateExternalBenchmarkDetails(benchmarkDetail))
         }
        dispatch(showMessage("sucess", "Success", _l(response.message)));
      }else {
        dispatch(showMessage("unsucess", _l("l_error"), response.message ? _l(response.message) : _l("l_something_went_wrong")));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStatusChangeLoader(false);
    }
  }

  const deleteProposalHandler = (proposal, isFromDetails = false) => {
    try {
      dispatch(toastCloseAction());
      customerServices.setProposalDelete(proposal.project_id, proposal.proposal_id, proposal.id).then((res) => {
        if (res.status) {
          const updateProposals = proposalList.filter((pro) => proposal.id != pro.id);
          setProposalList(updateProposals);
          writeToDB("proposals", `all_proposals`, {filter_key: `all_proposals`, data: updateProposals}, "filter_key");
          dispatch(showMessage("sucess", "Success", res && res.message ? _l(res.message) : "delete")); 
          if (isFromDetails) {
            dispatch(setShowAccordionVisibility(true));
            changeStep(STEPS.BACK)
            if (!["edit-address", "edit-phonenumber", "edit-email", "edit-full-name"].includes(currentStep)) {
              dispatch(commanoffcanvasisOpen(false));
              addExpandedClasstoIntroOfficeList();
            }
          }
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  const showTaskDetails = (task) => {
    customerServices.getTaskData(0,task).then((res)=>{
      if (res && res.status) {
        setTaskDetail(res.data);
        setShowTaskCanvas(true);
      }
    })
  }

  useEffect(() => {
    const fetchedRegions = () => {
      CustomerServices.getRegionList()
        .then((res) => {
          if (res.status == 1 && res.data) {
            setRegionList(
              res.data.map((data) => {
                return {
                  label: data.name,
                  value: data.id,
                };
              })
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsPageLoaded(true);
        });
    };

    fetchedRegions();
    // document.body.classList.remove("with-left-penel");

    const element = document.getElementById("left-panel-main");
    if (element) {
      // element.classList.add("d-none");
    }

    if (token) {

      if(projectId > 0 && proposalId > 0 && benchmarkId > 0)
      {
        viewProposalDetail({id: benchmarkId, project_id: projectId});
        searchParams.delete("space");
        searchParams.delete("proposal");
        searchParams.delete("id");
        setSearchParams(searchParams);
        getProposalList(false);
      }
      else {
        const client_id = localStorage.getItem("client_id");
        const contact_id = localStorage.getItem("contact_id");
        dispatch(getCustomerSpaces(client_id, contact_id));
        // setShowLoader(true);
        if (isTaskProposal && Object.keys(isTaskProposal).length > 0) {
          setShowLoader(false);
        }else{
          getProposalList();
        }
        if (element) {
          element.classList.remove("d-none");
          // hideLeftPanelNew();
        }
      }
    } else if (isExternal) {
      getExternalProposals();
    } else {
      setCurrentStep(isLocalEnvironment() ? "company_name" : "address");
    }
    dispatch(setReminderList("proposal",0))
  }, []);

  //To manage the step related data like the header text, progress bar, back & next button, etc
  useEffect(() => {
    let textContentData = {
      backButtonText: "",
      headerTitle: "l_solar_address_step_header",
      nextButtonText: "l_next",
      stepDescription: "",
      stepperProgress: 0,
      stepTitle: "",
    };

    document.body.classList.remove("px-0");

    switch (currentStep) {
      case "address":
        document.body.classList.add("px-0");
        textContentData = {
          backButtonText: "",
          headerTitle: "l_solarprovider_address_step_header",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(0),
          stepTitle: "",
          stepCount: 1,
        };
        break;
      case "company_name":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_company_name_step_header",
          nextButtonText: "",
          stepDescription: "l_what_is_the_name_of_your_business_description",
          stepperProgress: calculatePercentage(1),
          stepTitle: "l_what_is_the_name_of_your_business",
          stepCount: 2,
        };
        break;
      case "full-name":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_full_name_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_full_name_step_description",
          stepperProgress: calculatePercentage(2),
          stepTitle: "l_solar_full_name_step_title",
          stepCount: 3,
        };
        break;
      case "email":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_email_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_user_email_step_description",
          stepperProgress: calculatePercentage(3),
          stepTitle: "l_solar_user_email_step_title",
          stepCount: 4,
        };
        break;
      case "phonenumber":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_phonenumber_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_user_phonenumber_step_description",
          stepperProgress: calculatePercentage(4),
          stepTitle: "l_solar_user_phonenumber_step_title",
          stepCount: 5,
        };
        break;
      case "password":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_password_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_user_password_step_description",
          stepperProgress: calculatePercentage(5),
          stepTitle: "l_solar_user_password_step_title",
          stepCount: 6,
        };
        break;
      case "documents":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_password_step_header",
          nextButtonText: "l_access_my_provider_account",
          stepDescription: "",
          stepperProgress: calculatePercentage(6),
          stepTitle: "l_upload_your_company_logo_and_documents",
          stepCount: 7,
        };
        break;
      case "congratulations":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_congratulations_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_congratulations_step_description",
          stepperProgress: calculatePercentage(7),
          stepTitle: "l_solar_congratulations_step_title",
          stepCount: 8,
        };
        break;
      case "proposal-list":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_my_proposals",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(8),
          stepTitle: "",
        };
        break;

      case "stepper-loader":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_stepper_loader_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_stepper_loader_step_description",
          stepperProgress: calculatePercentage(9),
          stepTitle: "l_solar_stepper_loader_step_title",
          showCancelButton: true,
        };
        break;

      case "proposal-detail":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_provider_proposal_list_step_header",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(10),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 9,
        };
        break;
      case "tasks":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_solar_tasks_step_header",
          nextButtonText: "l_access_my_account",
          stepDescription: "",
          stepperProgress: calculatePercentage(11),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 10,
        };
        break;
      case "edit-address":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_map_address",
          nextButtonText: "l_next",
          stepDescription: "",
          stepperProgress: 0,
          stepTitle: "",
          showCancelButton: true,
          stepCount: 1,
        };
        break;
      case "edit-full-name":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_full_name_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_full_name_step_description",
          stepperProgress: 0,
          stepTitle: "l_solar_full_name_step_title",
          stepCount: 2,
        };
        break;
      case "edit-email":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_user_email_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_user_email_step_description",
          stepperProgress: 0,
          stepTitle: "l_solar_user_email_step_title",
          stepCount: 3,
        };
        break;
      case "edit-phonenumber":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "l_solar_phonenumber_step_header",
          nextButtonText: "",
          stepDescription: "l_solar_user_phonenumber_step_description",
          stepperProgress: 0,
          stepTitle: "l_solar_user_phonenumber_step_title",
          stepCount: 4,
        };
        break;
    }
    setTextContent(textContentData);

    if (currentStep == "proposal-list" || currentStep == "proposal-detail" ) {
      dispatch(setShowIsChatScreenDisplay(true));
    }else{
      dispatch(setShowIsChatScreenDisplay(false));
    }

    if (currentStep == "proposal-list") {
      showLeftPanelNew();
      addExpandedClasstoIntroOfficeList();
    } else {
      hideLeftPanelNew();
    }
    if (currentStep == "proposal-detail") {
      setUserInfo({ ...userInfo, is_edit: false});
    }
  }, [currentStep]);
  
  useEffect(() => {
   if(["proposal-list"].includes(leftPanelTab)){
     if (isTaskProposal && Object.keys(isTaskProposal).length > 0) {
      
     }else{
       getProposalList(); 
       setCurrentStep("proposal-list")
     }
    }

    if (!["my-clients"].includes(leftPanelTab)) {
      localStorage.removeItem("activeGroupId")
    }
    dispatch(setSelectedGloablView("AichatView"))
    if (leftPanelTab == "adminstrative-list") {
      // dispatch(getBenchmarkList(0, 0,[],0,[],"administration"));
      dispatch(setAdministrativeList(0, 0,[],0,[],"administration" , setShowSckeleton));

    }else if (leftPanelTab == "installation-list") {
      // dispatch(getBenchmarkList(0, 0,[],0,[],"installation"));
      dispatch(setInstallationList(0, 0,[],0,[],"installation", setShowSckeleton));
    }


  }, [leftPanelTab]);

  useEffect(() => {
    if (leftPanelTab == "proposal-list" && !["address",
    "company_name",
    "full-name",
    "email",
    "phonenumber",
    "password",
    "documents", ""].includes(currentStep)) {
      setCurrentStep("proposal-list")
    }
  }, [leftPanelTab])
  
  useEffect(() => {
    if (!["my-clients"].includes(leftPanelTab)) {
      localStorage.removeItem("activeGroupId")
    }
    if(["proposal-list"].includes(leftPanelTab)){
      // setCurrentStep("proposal-list");
      showLeftPanelNew();
      addExpandedClasstoIntroOfficeList();
    }
    dispatch(setSelectedGloablView("AichatView"))

    // if (["adminstrative-list", "installation-list" , "proposal-list"].includes(leftPanelTab)) {
    //   removeModifiedClass()
    // } else {
    //   addModifiedClass()
    // }

  }, [leftPanelTab]);

  useEffect(() => {
    let textContentData = {
      backButtonText: "",
      headerTitle: "",
      nextButtonText: "",
      stepDescription: "",
      stepperProgress: 0,
      stepTitle: "",
    };
    switch (leftPanelTab) {
      case "adminstrative-list":
        setTextContent({
          backButtonText: "",
          headerTitle: "Administrative",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: 0,
          stepTitle: "",
        });
        break;
      case "installation-list":
        setTextContent( {
          backButtonText: "",
          headerTitle: "Installation",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: 0,
          stepTitle: "",
        });
        
        break;
      default:
        switch (currentStep) {
          case "address":
            document.body.classList.add("px-0");
            textContentData = {
              backButtonText: "",
              headerTitle: "l_solarprovider_address_step_header",
              nextButtonText: "",
              stepDescription: "",
              stepperProgress: calculatePercentage(0),
              stepTitle: "",
              stepCount: 1,
            };
            break;
          case "company_name":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_company_name_step_header",
              nextButtonText: "",
              stepDescription: "l_what_is_the_name_of_your_business_description",
              stepperProgress: calculatePercentage(1),
              stepTitle: "l_what_is_the_name_of_your_business",
              stepCount: 2,
            };
            break;
          case "full-name":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_user_full_name_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_full_name_step_description",
              stepperProgress: calculatePercentage(2),
              stepTitle: "l_solar_full_name_step_title",
              stepCount: 3,
            };
            break;
          case "email":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_user_email_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_user_email_step_description",
              stepperProgress: calculatePercentage(3),
              stepTitle: "l_solar_user_email_step_title",
              stepCount: 4,
            };
            break;
          case "phonenumber":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_phonenumber_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_user_phonenumber_step_description",
              stepperProgress: calculatePercentage(4),
              stepTitle: "l_solar_user_phonenumber_step_title",
              stepCount: 5,
            };
            break;
          case "password":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_user_password_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_user_password_step_description",
              stepperProgress: calculatePercentage(5),
              stepTitle: "l_solar_user_password_step_title",
              stepCount: 6,
            };
            break;
          case "documents":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_user_password_step_header",
              nextButtonText: "l_access_my_provider_account",
              stepDescription: "",
              stepperProgress: calculatePercentage(6),
              stepTitle: "l_upload_your_company_logo_and_documents",
              stepCount: 7,
            };
            break;
          case "congratulations":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_congratulations_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_congratulations_step_description",
              stepperProgress: calculatePercentage(7),
              stepTitle: "l_solar_congratulations_step_title",
              stepCount: 8,
            };
            break;
          case "proposal-list":
            textContentData = {
              backButtonText: "",
              headerTitle: "l_my_proposals",
              nextButtonText: "",
              stepDescription: "",
              stepperProgress: calculatePercentage(8),
              stepTitle: "",
            };
            break;
    
          case "stepper-loader":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_stepper_loader_step_header",
              nextButtonText: "",
              stepDescription: "l_solar_stepper_loader_step_description",
              stepperProgress: calculatePercentage(9),
              stepTitle: "l_solar_stepper_loader_step_title",
              showCancelButton: true,
            };
            break;
    
          case "proposal-detail":
            textContentData = {
              backButtonText: "l_back",
              headerTitle: "l_solar_provider_proposal_list_step_header",
              nextButtonText: "",
              stepDescription: "",
              stepperProgress: calculatePercentage(10),
              stepTitle: "",
              showCancelButton: true,
              stepCount: 9,
            };
            break;
          case "tasks":
            textContentData = {
              backButtonText: "",
              headerTitle: "l_solar_tasks_step_header",
              nextButtonText: "l_access_my_account",
              stepDescription: "",
              stepperProgress: calculatePercentage(11),
              stepTitle: "",
              showCancelButton: true,
              stepCount: 10,
            };
            break;
        }
        setTextContent(textContentData);

        break;
    }
  }, [leftPanelTab])
  
  useEffect(() => {
    if (globalSearchString) {
      setProposalList(iniProposalList.filter((fi)=> fi.title.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase()) || fi.company_name.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase()) || fi.address.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase())))
    }else{
      setProposalList(iniProposalList)
    }  
  }, [globalSearchString])  

  useEffect(() => {
    const getContextData = async () => {
      try {
        const response = await benchmarkServices.getInvitedCustomerDetail(benchmarkDetail.id, benchmarkDetail.project_id);
        if (response.status) {
          const { firstname, lastname, email, phonenumber } = response.data
          setUserInfo({...userInfo, fullName: `${firstname} ${lastname}`, email: email, phonenumber: phonenumber })
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      } finally {
      }
    };
    if (userInfo.is_edit) {
      getContextData();
    }
  }, [userInfo.is_edit]);

  useEffect(() => {
    if (operatorProposalList && operatorProposalList.length > 0) {
      setProposalList(operatorProposalList)
    }
  }, [JSON.stringify(operatorProposalList)])

  useEffect(() => {
    if (isTaskProposal && Object.keys(isTaskProposal).length > 0) {
      viewProposalDetail(isTaskProposal);
      dispatch(setTaskProposal({}));
    }
  }, [isTaskProposal])
          
  return (
    <div className={`d-flex flex-column flex-grow-1 RegNewFlow pb-0 h-100 ${currentStep === "documents" ? "" : 'overflow-hidden'} `}>
      <React.Fragment>
        {/* show full loader instead of the stepper loader as per the feedback */}
        {showStepperLoading ||
        showLoader ||
        (isLoadingBenchmarkInfo && benchmarkId <= 0) ? (
          <Loader />
        ) : (
          <React.Fragment>

            { ["proposal-list"].includes(currentStep) || [].includes(leftPanelTab) ? <>
            <Header
            projectId={0}
            removeStatusFilter={()=>{}}
            setShowMailModal={()=>{}}
            setSynchronizedMail={()=>{}}
            showMailModal={false}
            hideDriveSyncBtn={true}
            pageName={"solar-proposals"}
            />
            </> 
            :<StepperHeader
              backButtonHandler={() => {
                dispatch(setShowAccordionVisibility(true));
                changeStep(STEPS.BACK)
                if (!["edit-address", "edit-phonenumber", "edit-email", "edit-full-name"].includes(currentStep)) {
                  dispatch(commanoffcanvasisOpen(false));
                  addExpandedClasstoIntroOfficeList();
                }
              }}
              nextButtonHandler={() => changeStep(STEPS.NEXT)}
              textContent={textContent}
              transformClass={
                currentStep === "proposal-list" ? "transformX-0" : ""
              }
              headerClass={ currentStep === "proposal-detail" || ["edit-address", "edit-phonenumber", "edit-email", "edit-full-name"].includes(currentStep) ? "ms75px" : ""}
              hideStepSpan={["proposal-list"].includes(currentStep)}
              stepName={currentStep}
              pageName= {["address","company_name","full-name","email","phonenumber","password","documents", ""].includes(currentStep) ? "" : "solarProviderRegistration"}
              setIsUpdate={setIsUpdate}
              loadingNextStep={showLoaderHeader}
              handler={(addreess) => { 
                setSolarInfo({
                  ...solarInfo ,
                  address : addreess,
                  aids_financial :benchmarkDetail.solar_items.aids_financial,
                  air_conditioner :benchmarkDetail.solar_items.air_conditioner,
                  back_yard :benchmarkDetail.solar_items.back_yard,
                  electricity_production :benchmarkDetail.solar_items.electricity_production,
                  electronic_gadgets :benchmarkDetail.solar_items.electronic_gadgets,
                  garage :benchmarkDetail.solar_items.garage,
                  monthly_bill :benchmarkDetail.solar_items.monthly_bill,
                  no_of_plates :benchmarkDetail.solar_items.no_of_plates,
                  number_of_batteries :benchmarkDetail.solar_items.number_of_batteries,
                  plate_capacity :benchmarkDetail.solar_items.plate_capacity,
                  savings :benchmarkDetail.solar_items.savings,
                  is_edit_page : true,
                })
                setUserInfo({ ...userInfo, is_edit: true});
                setCurrentStep("edit-address")
              }}
              deleteProposalHandler={deleteProposalHandler}
              setOpenNoteModal={setOpenNoteModal}
              setproposalModal={setproposalModal}
            />}
            {showStepperLoading ? (
              <div className="solar-acknowledge-screen-main">
                <SolarAcknowledgeScreen
                  afterDismissAction={() => {}}
                  delay={7000}
                  steps={ACKNOWLEDGEMENT_STEPS["solar_plan_1"]}
                  description={
                    "l_feature_allows_experience_entirely_new_way_interacting"
                  }
                  title={"l_onboarding_acknowledgement_header_title"}
                  isSolar={true}
                />
              </div>
            ) : (
              <></>
            )}
            {showCongratulationLoader ? (
              <Congratulation
                congratulationMessage={"l_cong_subtitle"}
                buttonText={_l("l_access_my_provider_account")}
                buttonOnclickHandler={() => getProposalList()}
              />
            ) : (
              leftPanelTab == "installation-list" ? <>
              <InstallationList showSckeleton={showSckeleton}/>
              </> :
              leftPanelTab == "adminstrative-list" ? <>
                <AdminstrativeList
                showSckeleton={showSckeleton} 
                />
              </> :
               leftPanelTab == "solairedashboard" ? <>
               <SolaireDashabord />
             </> :
              (<React.Fragment>
                {currentStep === "address"  && <div
                  className={`${
                    currentStep === "address" ? "h-100" : "d-none"
                  }`}
                  ref={mapScreenRef}
                >
                  {token || (benchmarkDetail && benchmarkDetail.id > 0) ? (
                    // Do not load the iFrame after benchmark creation, as user is no longer able to edit it
                    <React.Fragment></React.Fragment>
                  ) : (
                    <Address
                      coOrdinates={coOrdinates}
                      regionList={regionList}
                      setCoOrdinates={setCoOrdinates}
                      setRegionList={setRegionList}
                      setSpaceDetail={setUserInfo}
                      spaceDetail={userInfo}
                      nextButtonHandler={() => changeStep(STEPS.NEXT)}
                    />
                  )}
                </div>}
                {currentStep === "company_name" ? (
                  <CompanyInput
                    type={"text"}
                    value={userInfo.company_name}
                    placeholder={_l("l_name_of_your_business")}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        company_name: e.target.value,
                      })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "full-name" ? (
                  <NameInput
                    type={"text"}
                    value={userInfo.fullName}
                    placeholder={_l("l_your_first_and_last_name")}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, fullName: e.target.value })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "phonenumber" ? (
                  <CustomPhoneInput
                    onChange={(phone) =>
                      setUserInfo({ ...userInfo, phonenumber: phone })
                    }
                    value={userInfo.phonenumber}
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "email" ? (
                  <EmailInput
                    type={"text"}
                    value={userInfo.email}
                    placeholder="sample@domain.com"
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, email: e.target.value })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "password" ? (
                  <PasswordInput
                    type={"password"}
                    value={userInfo.password}
                    placeholder={_l("l_enter_your_password")}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, password: e.target.value })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "documents" ? (
                  <ProviderUploadDocument
                    value={userInfo.company_documents}
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, company_documents: e })
                    }}
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                    isDocumentPageRegistration={true}
                  />
                ) : currentStep === "proposal-list" ? (
                  <OperatorProposalList
                    allProposals={proposalList}
                    onClick={viewProposalDetail}
                    isExternal={isExternal}
                    setProposalList={setProposalList}
                    changeTaskStatus = {changeTaskStatus}
                    deleteProposalHandler={deleteProposalHandler}
                    setOpenNoteModal={setOpenNoteModal}
                    showTaskDetails={showTaskDetails}
                  />
                
                ) : currentStep === "proposal-detail" ? (
                  <ProviderProposalDetail
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                    handler={(addreess) => {
                      setSolarInfo({
                        ...solarInfo ,
                        address : addreess,
                        aids_financial :benchmarkDetail.solar_items.aids_financial,
                        air_conditioner :benchmarkDetail.solar_items.air_conditioner,
                        back_yard :benchmarkDetail.solar_items.back_yard,
                        electricity_production :benchmarkDetail.solar_items.electricity_production,
                        electronic_gadgets :benchmarkDetail.solar_items.electronic_gadgets,
                        garage :benchmarkDetail.solar_items.garage,
                        monthly_bill :benchmarkDetail.solar_items.monthly_bill,
                        no_of_plates :benchmarkDetail.solar_items.no_of_plates,
                        number_of_batteries :benchmarkDetail.solar_items.number_of_batteries,
                        plate_capacity :benchmarkDetail.solar_items.plate_capacity,
                        savings :benchmarkDetail.solar_items.savings,
                        is_edit_page : true
                      })
                      setUserInfo({ ...userInfo, is_edit: true});
                      setCurrentStep("edit-address")
                    }}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    setShowLoaderHeader={setShowLoaderHeader}
                    changeTaskStatus = {changeTaskStatus}
                    showTaskDetails={showTaskDetails}
                  />
                ) : currentStep == "edit-address" ? (
                  <>
                  <MapScreen
                    solarInfo={solarInfo}
                    setSolarInfo={setSolarInfo}
                    regionList={regionList}
                    setRegionList={setRegionList}
                    mapScreenRef={mapScreenRef}
                    captureMapScreenShot={captureMapScreenShot}
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                    setAddressImageURL={setAddressImageURL}
                    setCoordinates={setCoordinates}
                    coordinates={coordinates}
                    setIsSolarDataFetched={setIsSolarDataFetched}
                    isSolarDataFetched={isSolarDataFetched}
                    showInput={showInput}
                    setShowInput={setShowInput}
                    />
                  </>
                ) : currentStep === "edit-full-name" ? (
                  <NameInput
                    type={"text"}
                    value={userInfo.fullName}
                    placeholder={_l("l_your_first_and_last_name")}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, fullName: e.target.value })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "edit-phonenumber" ? (
                  <CustomPhoneInput
                    onChange={(phone) =>
                      setUserInfo({ ...userInfo, phonenumber: phone })
                    }
                    value={userInfo.phonenumber}
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : currentStep === "edit-email" ? (
                  <EmailInput
                    type={"text"}
                    value={userInfo.email}
                    placeholder="sample@domain.com"
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, email: e.target.value })
                    }
                    handleNextStep={() => changeStep(STEPS.NEXT)}
                  />
                ) : (
                  <></>
                )}
              </React.Fragment>)
            )}
            <StepperFooter
              FooterJSXElement={
                HIDE_FOOTER.find((w) => w == currentStep) ? (
                  <></>
                ) : (
                  <FooterJSX />
                )
              }
            />
            {["my-clients" ,].includes(leftPanelTab) ? <Footer
            pageName={"solar-proposals"}
            projectId={0}
            setIsKanbanView={()=>{}}
            /> : <React.Fragment></React.Fragment>}
          </React.Fragment>
        )}
      </React.Fragment>
      {
        openNoteModal.modal && openNoteModal.id > 0 ? 
         <NoteProposalModal 
            handleClose={() => {
              setOpenNoteModal({modal : false, id : 0});
            }}
            openNoteModal={openNoteModal}
        />
         : <></>
      }
      { proposalModal.modal ?
        <ActivityOrEmailTrackingModal 
          handleClose={ () => {setproposalModal({modal : false})}}
          proposalModal={proposalModal}
         />
        :<></>
      }
      {showTaskCanvas ? (
        <CommanOffCanvas
          show={showTaskCanvas}
          handleClose={() => {
            setShowTaskCanvas(false);
          }}
        //   pinHandler={(id, type, flag) => {
        //     handlePinUnPin(id, type, flag);
        //   }}
          data={taskDetail}
          setData={()=>{}}
          docType={'task'}
          setSelectedId={()=>{}}
          is_fullscreen={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SolarProviderRegistration;
